import axios from "axios";
import { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const EditUser = (props) => {
  const { url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));

  const [fullname, setFullname] = useState(props.user.full_name);
  const [email, setEmail] = useState(props.user.email_address);
  const [mobile, setMobile] = useState(props.user.mobile_number);
  const [address1, setAddress1] = useState(props.user.address1);
  const [address2, setAddress2] = useState(props.user.address2);
  const [province, setProvince] = useState(props.user.province_id);
  const [postalCode, setPostalCode] = useState(props.user.postal_code);
  const [provinceList, setProvinceList] = useState([]);

  useEffect(() => {
    console.log(props.user);
    getProvinces();
  }, []);

  const getProvinces = async () => {
    const data = {
      user_id: sessionStorage.getItem("id"),
    };

    await axios
      .post(url + "api/basic/data", data)
      .then((response) => {
        if (response?.status === 200) {
          setProvinceList(response.data.output.province);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const userUpdate = (e) => {
    e.preventDefault();

    const data = {
      user_id: props.user.user_id,
      user_type_id: props.user.user_type_id,
      full_name: fullname,
      email_address: email,
      mobile_number: mobile,
      address1: address1,
      address2: address2,
      province_id: province,
      postal_code: postalCode,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "api/user/manage", data, config)
      .then((response) => {
        if (response?.status === 200) {
          props.toastPopup(1, response.data.message);
          props.fetchData(token);
          props.closeModal();
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        props.toastPopup(2, error.message);
      });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          Edit Participant Data
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal();
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full" id="myForm" onSubmit={userUpdate}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Full Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="full Name"
                required
                value={fullname}
                onChange={(e) => {
                  setFullname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Email Address
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="email"
                placeholder="Email Address"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Mobile Number
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="phone"
                placeholder="Phone Number"
                required
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Address Line 1
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Address Line 1"
                required
                value={address1}
                onChange={(e) => {
                  setAddress1(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Address Line 2
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Address Line 2"
                value={address2}
                onChange={(e) => {
                  setAddress2(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Province
              </label>
              <select
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Province"
                required
                value={province}
                onChange={(e) => {
                  setProvince(e.target.value);
                }}
              >
                {provinceList.map((province) => {
                  return (
                    <option value={province.id}>{province.province}</option>
                  );
                })}
              </select>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Postal Code
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="Number"
                placeholder="Postal Code"
                required
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal();
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditUser;
