import axios from "axios";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const SubscriptionUpdate = (props) => {
  const { url } = useStateContext();
  const [defaultPrice, setDefaultPrice] = useState(props.data.premium_package_price);
  const [monthlyPrice, setMonthlyPrice] = useState(props.data.premium_package_monthly_price);
  const [yearlyPrice, setYealyPrice] = useState(props.data.premium_package_annual_price);
  const [dollarRate, setDollarRate] = useState(props.data.dollar_rate);

  const update = async () => {
    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };

    const bodyParameters = {
      premium_package_price: defaultPrice,
      premium_package_monthly_price: monthlyPrice,
      premium_package_annual_price: yearlyPrice,
      dollar_rate: dollarRate,
    };

    await axios
      .post(url + "api/add/setting/data", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.fetchData();
            props.closeModal();
        } else {
            props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        props.toastPopup(2, error.message);
      });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          Subscription Price Update
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal();
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Default Subscription
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="default-price"
                type="number"
                placeholder="Default Price"
                required
                value={defaultPrice}
                onChange={(e) => {
                  setDefaultPrice(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Monthly Subscription
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="monthly-price"
                type="number"
                placeholder="Monthly  Price"
                required
                value={monthlyPrice}
                onChange={(e) => {
                  setMonthlyPrice(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Yealy Subscription
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="yearly-price"
                type="number"
                placeholder="Yearly Price"
                required
                value={yearlyPrice}
                onChange={(e) => {
                  setYealyPrice(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Dollar Rate
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="dollar-rate"
                type="number"
                placeholder="Dollar Rate"
                required
                value={dollarRate}
                onChange={(e) => {
                  setDollarRate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal();
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              onClick={update}
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Update
            </button>
          </div>
      </div>
    </div>
  );
};
export default SubscriptionUpdate;
