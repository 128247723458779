import axios from "axios";
import { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useStateContext } from "../contexts/ContextProvider";

const SupportView = (props) => {
  const { url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));

  const [supports, setSupports] = useState([]);
  const [userid, setUserid] = useState();

  useEffect(() => {
    setUserid(props.support.user_id);
    loadSupportLog();
  }, []);

  const loadSupportLog = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const data = {
      support_id: props.support.support_id,
    };

    await axios
      .post(url + "api/support/log", data, config)
      .then((response) => {
        setSupports(response.data.output);
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">Support View</p>
        <button
          type="button"
          onClick={() => {
            props.closeModal();
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-1/4 px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-last-name"
          >
            Support Id -: {props.support.support_id}
          </label>
        </div>
      </div>

      <Tabs>
        <TabList>
          <Tab>Support</Tab>
          <Tab>User Details</Tab>
          <Tab>Support Details</Tab>
          <Tab>Assigner Details</Tab>
        </TabList>

        <TabPanel>
          <div className="flex flex-col w-full gap-2">
            {supports.map((support, x) => {
              return (
                <div
                  className={`flex flex-col rounded-lg py-1 px-2 w-fit ${
                    support.user_id === userid
                      ? "self-start bg-slate-300"
                      : "self-end bg-slate-100"
                  }`}
                  key={x}
                >
                  <div className="flex text-sm font-semibold">
                    {support.title}
                  </div>
                  <div className="flex text-sm">{support.description}</div>
                  <div className="flex">
                    <img src={support.img_path} />
                  </div>
                </div>
              );
            })}
          </div>
        </TabPanel>
        <TabPanel>
          {/* User Details  */}
          <div
            className={
              props.support.profile_picture === null
                ? "hidden"
                : "visible flex flex-wrap -mx-3 mb-6"
            }
          >
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Profile Picture
              </label>
              <img
                className="appearance-none block w-40 bg-gray-200 text-gray-700 border rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                src={props.support.profile_picture}
                alt="user-img"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Full Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                disabled
                value={props.support.full_name}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Address
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                disabled
                value={props.support.address1 + ", " + props.support.address2}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Email Address
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                disabled
                value={props.support.email_address}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Mobile Number
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                disabled
                value={props.support.mobile_number}
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          {/* Support Details */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Support Title
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                disabled
                value={props.support.title}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Support Description
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="textarea"
                disabled
                value={props.support.description}
              />
            </div>
          </div>

          <div
            className={
              props.support.attachment_1 == null
                ? "hidden"
                : "flex flex-wrap -mx-3 mb-6"
            }
          >
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Attachment 1
              </label>
              <img
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                src={props.support.attachment_1}
                alt="attachment1"
              />
            </div>
          </div>

          <div
            className={
              props.support.attachment_2 == null
                ? "hidden"
                : "flex flex-wrap -mx-3 mb-6"
            }
          >
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Attachment 2
              </label>
              <img
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                src={props.support.attachment_2}
                alt="attachment2"
              />
            </div>
          </div>

          <div
            className={
              props.support.attachment_3 == null
                ? "hidden"
                : "visible flex flex-wrap -mx-3 mb-6"
            }
          >
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Attachment 3
              </label>
              <img
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                src={props.support.attachment_3}
                alt="attachment3"
              />
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          {/* Assinger Details */}
          {props.support.assigner_id === null ? (
            <div className="w-full text-center p-4 m-4">No assigned User!</div>
          ) : (
            <div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Assigner Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    disabled
                    value={props.support.asigner_name}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Assigner Email Address
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    disabled
                    value={props.support.asigner_email}
                  />
                </div>
              </div>
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};
export default SupportView;
