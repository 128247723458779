import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Header, EditWord, Pagination } from "../components";
import DeleteWord from "../components/DeleteWord";
import NewWord from "../components/NewWord";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "55%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Dictionary = () => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");
  const [words, setWords] = useState([]);
  const [selectedWord, setSelectedWord] = useState();

  const [wordAddIsOpen, setWordAddIsOpen] = useState(false);
  const [wordEditIsOpen, setWordEditIsOpen] = useState(false);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = words.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(words.length / recordsPerPage);

  useEffect(() => {
    getWordData();
  }, []);

  const getWordData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      user_id: 0,
    };

    await axios
      .post(url + "api/word/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setWords(response.data.output.admin_word);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const WordEdit = (word) => {
    setSelectedWord(word);
    setWordEditIsOpen(true);
  };

  const closeModal = (id) => {
    if (id === 1) {
      setWordAddIsOpen(false);
    } else {
      setWordEditIsOpen(false);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Dictionary" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setWordAddIsOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            Add Word
          </button>
        </div>
      </div>

      <Modal
        isOpen={wordAddIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Word"
      >
        <NewWord
          fetchData={getWordData}
          toastPopup={toastPopup}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={wordEditIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Word"
      >
        <EditWord
          toastPopup={toastPopup}
          getWordData={getWordData}
          word={selectedWord}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">ID</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Date</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                words.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={5} className="py-4 px-6 text-center justify-center">
                No Words
              </td>
            </tr>
            {currentRecords.map((word, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6">{indexOfFirstRecord + row}</td>
                  <td className="py-4 px-6">{word.title}</td>
                  <td className="py-4 px-6">{word.description}</td>
                  <td className="py-4 px-6">{word.view_date}</td>
                  <td className="py-4 px-6 text-center justify-center">
                    <DeleteWord
                      word_id={word.id}
                      getWordData={getWordData}
                      toastPopup={toastPopup}
                    />
                    &nbsp;
                    <button
                      className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        WordEdit(word);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Dictionary;
