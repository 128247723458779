import React from "react";

const UserType = (props) => {
  const { id } = props;

  return (
    <div
      className={`${
        id === 1 ? "bg-yellow-600" : "bg-green-600"
      } text-white font-normal text-base text-center rounded-md w-fit px-2`}
    >
      {id === 1 ? "FREE" : "PREMIUM"}
    </div>
  );
};

export default UserType;
