import { Header, Pagination } from "../components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import DefAvatar from "../data/avatar.png";
import StatusLabel from "../components/StatusLabel";
import StatusUpdate from "../components/StatusUpdate";
import { toast } from "react-toastify";
import Modal from "react-modal";
import NewUser from "../components/NewUser";
import { useStateContext } from "../contexts/ContextProvider";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const SystemUsers = () => {
  const { url } = useStateContext();
  const [users, setUser] = useState([]);
  const [isNewUserAddOpen, setIsNewUserAddOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = users.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(users.length / recordsPerPage);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    getData(token);
  }, []);

  const getData = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      user_id: 0,
      user_type_id: 2,
      search_data: null,
    };

    await axios
      .post(url + "api/users", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          let newArray = response.data.output;
          setUser(newArray.reverse());
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const closeModal = () => {
    setIsNewUserAddOpen(false);
  };

  return (
    <div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <div className="flex flex-row">
          <div className="w-2/4">
            <Header category="User Management" title="System Users" />
          </div>

          <div className="w-2/4 text-right">
            <button
              type="button"
              onClick={() => {
                setIsNewUserAddOpen(true);
              }}
              className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
            >
              Add User
            </button>
          </div>
        </div>

        <Modal
          isOpen={isNewUserAddOpen}
          onRequestClose={closeModal}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          contentLabel="New User"
        >
          <NewUser
            fetchData={getData}
            toastPopup={toastPopup}
            closeModal={closeModal}
          />
        </Modal>

        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center cursor-pointer">
                    User Id
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1 w-3 h-3"
                      areia-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />{" "}
                    </svg>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center cursor-pointer">
                    Profile Picture
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center cursor-pointer">
                    Full Name
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1 w-3 h-3"
                      areia-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />{" "}
                    </svg>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center cursor-pointer">
                    Mobile Number
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1 w-3 h-3"
                      areia-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />{" "}
                    </svg>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex items-center cursor-pointer">Email</div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex  text-center justify-center cursor-pointer">
                    Status
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1 w-3 h-3"
                      areia-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 320 512"
                    >
                      <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />{" "}
                    </svg>
                  </div>
                </th>
                <th scope="col" className="py-3 px-6">
                  <div className="flex  text-center justify-center cursor-pointer">
                    Action
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                className={
                  users.length !== 0
                    ? "hidden"
                    : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                }
              >
                <td
                  colSpan={6}
                  className="py-4 px-6 text-center justify-center"
                >
                  No Users
                </td>
              </tr>
              {currentRecords.map((user, x) => {
                let row = x + 1;
                return (
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="py-4 px-6">{indexOfFirstRecord + row}</td>
                    <td className="py-4 px-6">
                      <img
                        src={
                          user.profile_picture === null
                            ? DefAvatar
                            : user.profile_picture
                        }
                        alt="profile"
                        className="w-16  rounded-[50%]"
                      />
                    </td>
                    <td className="py-4 px-6">{user.full_name}</td>
                    <td className="py-4 px-6">{user.mobile_number}</td>
                    <td className="py-4 px-6">{user.email_address}</td>
                    <td className="py-4 px-6">
                      <StatusLabel status={user.status} />
                    </td>
                    <td className="py-4 px-6 text-center justify-center">
                      <StatusUpdate
                        status={user.status}
                        user_id={user.user_id}
                        getData={getData}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};
export default SystemUsers;
