import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Pagination,
  SubQuestionAnswerAdd,
  SubQuestionAnswerEdit,
  SubQuestionEdit,
  SubQuestionsAdd,
} from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const QuestionView = () => {
  const { url } = useStateContext();
  const [subQuestionAddIsOpen, setIsSubQuestionAddOpen] = useState(false);
  const [subQuestionAnswerAddIsOpen, setIsSubQuestionAnswerAddOpen] =
    useState(false);
  const [subQuestionAnswerEditIsOpen, setIsSubQuestionAnswerEditOpen] =
    useState(false);
  const [subQuestionEditIsOpen, setIsSubQuestionEditOpen] = useState(false);

  let params = useParams();
  const token = sessionStorage.getItem("token");
  const examId = useState(params.exam_id);
  const questionId = useState(params.question_id);

  const [question, setQuestion] = useState();

  const [subQuestion, setSubQuestion] = useState();
  const [aMode, setAMode] = useState();

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = question?.sub_question.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(question?.sub_question.length / recordsPerPage);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      exam_id: examId[0],
      question_type_id: 0,
      question_mode_id: 0,
      question_category_id: 0,
      search_data: null,
      question_id: questionId[0],
      status: 1,
    };

    await axios
      .post(url + "api/exam/question/all", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          setQuestion(response.data.output.exams[0].questions[0]);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const deleteSubQuestion = async (qID) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      sub_question_id: qID,
      status: 0,
    };

    await axios
      .post(url + "api/sub/question/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          toastPopup(1, response.data.message);
          loadData();
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const closeModal = (id) => {
    if (id === 1) {
      setIsSubQuestionAddOpen(false);
    } else if (id === 2) {
      setIsSubQuestionAnswerAddOpen(false);
    } else if (id === 3) {
      setIsSubQuestionAnswerEditOpen(false);
    } else {
      setIsSubQuestionEditOpen(false);
    }
  };

  const addSubQuestionAnswers = (question) => {
    setSubQuestion(question);
    setAMode(question.answer_mode_id);
    setIsSubQuestionAnswerAddOpen(true);
  };

  const editSubQuestionAnswers = (question) => {
    setSubQuestion(question);
    setAMode(question.answer_mode_id);
    setIsSubQuestionAnswerEditOpen(true);
  };

  const editSubQuestion = (question) => {
    setSubQuestion(question);
    setIsSubQuestionEditOpen(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <div>
            <div className=" mb-10">
              <p
                className="text-lg text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: question?.question_title,
                }}
              ></p>
              <p
                className="text-2xl font-extrabold tracking-tight text-slate-900"
                dangerouslySetInnerHTML={{
                  __html: question?.question_description,
                }}
              ></p>
              <div className="w-full flex flex-row mt-2">
                <div className="text-center">
                  <label
                    className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                    for="grid-last-name"
                  >
                    {question?.question_type}
                  </label>
                </div>
                &nbsp;
                <div className="text-center">
                  <label
                    className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                    for="grid-last-name"
                  >
                    {question?.question_category}
                  </label>
                </div>
                &nbsp;
                <div className="text-center">
                  <label
                    className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                    for="grid-last-name"
                  >
                    Points: {question?.point}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsSubQuestionAddOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Sub Question
          </button>
        </div>
      </div>

      <Modal
        isOpen={subQuestionAddIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Sub Question Add"
      >
        <SubQuestionsAdd
          toastPopup={toastPopup}
          question={question?.question_id}
          exam={question?.exam_id}
          loadData={loadData}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={subQuestionAnswerAddIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Sub Question Answer Add"
      >
        <SubQuestionAnswerAdd
          toastPopup={toastPopup}
          question={subQuestion?.question_id}
          subquestion={subQuestion?.sub_question_id}
          answerMode={aMode}
          loadData={loadData}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={subQuestionAnswerEditIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Sub Question Answer Edit"
      >
        <SubQuestionAnswerEdit
          toastPopup={toastPopup}
          question={subQuestion}
          loadData={loadData}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={subQuestionEditIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Sub Question Edit"
      >
        <SubQuestionEdit
          exam={examId[0]}
          toastPopup={toastPopup}
          question={subQuestion}
          loadData={loadData}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Id
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Mode
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Category
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Points
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Answers
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                question?.sub_question.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={8} className="py-4 px-6 text-center justify-center">
                No Questions
              </td>
            </tr>
            {currentRecords &&
              currentRecords?.map((question, x) => {
                let row = x + 1;
                return (
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="py-4 px-6 text-center">
                      {indexOfFirstRecord + row}
                    </td>
                    <td
                      className="py-4 px-6"
                      dangerouslySetInnerHTML={{
                        __html: question.question_title,
                      }}
                    ></td>
                    <td
                      className="py-4 px-6"
                      dangerouslySetInnerHTML={{
                        __html: question.question_description,
                      }}
                    ></td>
                    <td className="py-4 px-6 text-center">
                      {question.question_mode}
                    </td>
                    <td className="py-4 px-6 text-center">
                      {question.question_category}
                    </td>
                    <td className="py-4 px-6 text-center">{question.point}</td>
                    <td className="py-4 px-6 text-center">
                      {question.answers === undefined
                        ? 0
                        : question.answers.length}
                    </td>
                    <td className="py-4 px-6 text-center">
                      <button
                        className="bg-green-600 hover:bg-green-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                        onClick={() => {
                          if (question.answers.length === 0) {
                            addSubQuestionAnswers(question);
                          } else {
                            editSubQuestionAnswers(question);
                          }
                        }}
                      >
                        Add/Edit Answers
                      </button>
                      &nbsp;
                      <button
                        className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                        onClick={() => {
                          editSubQuestion(question);
                        }}
                      >
                        Edit
                      </button>
                      &nbsp;
                      <button
                        onClick={() => {
                          deleteSubQuestion(question.sub_question_id);
                        }}
                        className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {question === undefined ? (
          <div></div>
        ) : (
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};
export default QuestionView;
