import { FormValidator } from "@syncfusion/ej2-react-inputs";
import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useStateContext } from "../../contexts/ContextProvider";
import { useState } from "react";
import axios from "axios";
import { LinearProgress } from "@mui/material";

const NewQuestion = (props) => {
  const token = sessionStorage.getItem("token");
  const user_id = sessionStorage.getItem("id");

  const { url } = useStateContext();

  const [qMode, setQMode] = useState(1);
  const [qType, setQType] = useState(1);
  const [qCategory, setQCategory] = useState(1);
  const [aMode, setAMode] = useState(1);

  const [points, setPoints] = useState(5);
  const [fileExtension, setFileExtension] = useState(null);
  const [file, setFile] = useState(null);
  const [fileExtension2, setFileExtension2] = useState(null);
  const [file2, setFile2] = useState(null);
  const [IsShuffle, setIsShuffle] = useState(1);

  const [progress, setProgress] = useState(0);

  const option = {
    rules: {
      "title-value": {
        required: true,
      },
    },
  };

  let xx = new FormValidator("#myForm", option);

  const newQuestion = async (e) => {
    e.preventDefault();

    const form = document.forms.myForm;
    const formData = new FormData(form);
    const title = formData.get("title-value");
    const description = formData.get("desc-value");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    };

    let data = new FormData();
    if (title === "") {
      props.toastPopup(3, "Question Title required!");
    } else {
      if (qMode === 1) {
        data.append("question_title", title);
        data.append("description", description);
        data.append("question_type_id", qType);
        data.append("answer_mode_id", aMode);
        data.append("question_category_id", qCategory);
        data.append("question_mode_id", qMode);
        data.append("point", points);
        data.append("file_extension", null);
        data.append("question_file", null);
        data.append("created_by", user_id);
        data.append("is_answer_rotate", IsShuffle);
        data.append("file_extension2", fileExtension2);
        data.append("image_file", file2);
      } else {
        data.append("question_title", title);
        data.append("description", description);
        data.append("question_type_id", qType);
        data.append("answer_mode_id", aMode);
        data.append("question_category_id", qCategory);
        data.append("question_mode_id", qMode);
        data.append("point", points);
        data.append("file_extension", fileExtension);
        data.append("question_file", file);
        data.append("created_by", user_id);
        data.append("is_answer_rotate", IsShuffle);
        data.append("file_extension2", fileExtension2);
        data.append("image_file", file2);
      }
    }

    if (qMode === 1) {
      await axios
        .post(url + "api/question/premium/add", data, config)
        .then((response) => {
          if (response?.status === 200) {
            props.toastPopup(1, response.data.message);
          } else {
            props.toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          props.toastPopup(2, error.message);
        })
        .finally(() => {
          props.loadQuestions();
          props.closeModal(1);
        });
    } else {
      if (file === null) {
        props.toastPopup(2, "Please select a file!");
      } else {
        await axios
          .post(url + "api/question/premium/add", data, config)
          .then((response) => {
            if (response?.status === 200) {
              props.toastPopup(1, response.data.message);
            } else {
              props.toastPopup(3, response.data.message);
            }
          })
          .catch((error) => {
            props.toastPopup(2, error.message);
          })
          .finally(() => {
            props.loadQuestions();
            props.closeModal(1);
          });
      }
    }
  };

  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setFileExtension(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler2 = (e) => {
    setFile2(e.target.files[0]);
    setFileExtension2(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">New Question</p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(1);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div>
        <form className="w-full" id="myForm" onSubmit={newQuestion}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                Question Type
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  value={qType}
                  onChange={(e) => {
                    setQType(e.target.value);
                    if (e.target.value === "1") {
                      setIsShuffle(1);
                      setQMode(1);
                    } else {
                      setIsShuffle(0);
                      setQMode(3);
                    }
                  }}
                >
                  <option value={1}>Reading Question</option>
                  <option value={2}>Listening Question</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                Question Category
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  value={qCategory}
                  onChange={(e) => {
                    setQCategory(e.target.value);
                  }}
                >
                  <option value={1}>Easy</option>
                  <option value={2}>Normal</option>
                  <option value={3}>Hard</option>
                  <option value={4}>Extra Hard</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            {/* Radio box */}
            <div className="w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Shuffle Question
              </label>
              <div className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <input
                  id="yes"
                  name="shuffle"
                  type="radio"
                  value="Yes"
                  checked={IsShuffle === 1}
                  onChange={(e) => {
                    setIsShuffle(e.target.checked ? 1 : 0);
                  }}
                />
                <label for="html" className="px-2">
                  Yes
                </label>
                &nbsp;
                <input
                  id="no"
                  name="shuffle"
                  type="radio"
                  checked={IsShuffle === 0}
                  value="No"
                  onChange={(e) => {
                    setIsShuffle(e.target.checked ? 0 : 1);
                  }}
                />
                <label for="css" className="px-2">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-row py-3">
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                Question Mode
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  value={qMode}
                  onChange={(e) => {
                    setQMode(e.target.value);
                  }}
                >
                  <option value={1}>Text</option>
                  <option value={2}>Image</option>
                  <option value={3}>Audio</option>
                  <option value={4}>Video</option>
                  <option value={5}>Image and text</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                Answer Mode
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  value={aMode}
                  onChange={(e) => {
                    setAMode(e.target.value);
                  }}
                >
                  <option value={1}>Text</option>
                  <option value={2}>Image</option>
                  <option value={3}>Audio</option>
                  <option value={4}>Video</option>
                  <option value={5}>Image and text</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Points
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="question-category"
                type="number"
                value={points}
                required
                onChange={(e) => {
                  setPoints(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Title
              </label>
              <RichTextEditorComponent
                id="title"
                htmlAttributes={{ name: "title-value" }}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description
              </label>
              <RichTextEditorComponent
                id="desc"
                htmlAttributes={{ name: "desc-value" }}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>

          {/* required image  */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <lable
                className="block uppercase tracking tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="gridp-last-name"
              >
                Image
              </lable>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                onChange={fileHandler2}
                accept="image/*"
                htmlAttributes={"required=true"}
              />
            </div>
          </div>

          <div className={qMode === 1 ? "hidden" : "flex flex-wrap -mx-3 mb-6"}>
            <div className="w-full px-3">
              <lable
                className="block uppercase tracking tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="gridp-last-name"
              >
                File &nbsp;
                <lable
                  className={
                    qMode === 2 || qMode === 5
                      ? "visible text-red-500"
                      : "hidden"
                  }
                >
                  (image/*)
                </lable>
                <lable
                  className={qMode === 3 ? "visible text-red-500" : "hidden"}
                >
                  (mp3/*)
                </lable>
                <label
                  className={qMode === 4 ? "visible text-red-500" : "hidden"}
                >
                  (mp4/*)
                </label>
              </lable>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                onChange={fileHandler}
                accept={
                  qMode === 2 || qMode === 5
                    ? "image/*"
                    : qMode === 3
                    ? "audio/*"
                    : qMode === 4
                    ? "video/*"
                    : ""
                }
                htmlAttributes={"required=true"}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(1);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Add
            </button>
          </div>
        </form>
        <br />
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </div>
  );
};

export default NewQuestion;
