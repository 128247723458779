import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { Header, Pagination } from "../components";
import DeleteVersion from "../components/DeleteVersion";
import NewVersion from "../components/NewVersion";
import StatusLabel from "../components/StatusLabel";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "65%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Version = () => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");
  const [versions, setVersions] = useState([]);

  const [isNewVersionAddOpen, setIsNewVersionAddOpen] = useState(false);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = versions.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(versions.length / recordsPerPage);

  useEffect(() => {
    getVersionData();
  }, []);

  const getVersionData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "api/version/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setVersions(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const closeModal = () => {
    setIsNewVersionAddOpen(false);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Version" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewVersionAddOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            Add Version
          </button>
        </div>
      </div>

      <Modal
        isOpen={isNewVersionAddOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Version"
      >
        <NewVersion
          fetchData={getVersionData}
          toastPopup={toastPopup}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Number</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Build Number
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  App Version
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Os Type</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Message</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Status</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                versions.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={4} className="py-4 px-6 text-center justify-center">
                No Versions
              </td>
            </tr>

            {currentRecords.map((version, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6 text-center justify-center">
                    {indexOfFirstRecord + row}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {version.build_number}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {version.app_version}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {version.os_type}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {version.message}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    <StatusLabel status={version.is_active} />
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    <DeleteVersion
                      version_id={version.id}
                      getVersionData={getVersionData}
                      toastPopup={toastPopup}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Version;
