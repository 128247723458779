import axios from "axios";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";

const StatusUpdate = (data) => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");

  const deleteUser = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      user_id: data.user_id,
    };

    await axios
      .post(url + "api/user/delete", bodyParameters, config)
      .then((response) => {
        toast.success(response.data.message);
        data.getData(token);
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  return (
    <>
      <button
        onClick={deleteUser}
        className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-2 rounded-md"
      >
        Delete
      </button>
    </>
  );
};

export default StatusUpdate;
