import { toast } from "react-toastify";
import { Header, SubscriptionUpdate } from "../components";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "50%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Subscriptions = () => {
  const { url } = useStateContext();
  const [isUpdateOpen, setUpdateOpen] = useState(false);
  const token = sessionStorage.getItem("token");

  const [data, setData] = useState();
  const [defaultPrice, setDefaultPrice] = useState(0);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [yearlyPrice, setYearlyPrice] = useState(0);
  const [defaultDollarPrice, setDefaultDollarPrice] = useState(0);
  const [monthlytDollarPrice, setMonthlyDollarPrice] = useState(0);
  const [yearlytDollarPrice, setYearlyDollarPrice] = useState(0);

  useEffect(() => {
    getSubscriptionData();
  }, []);

  const getSubscriptionData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {};

    await axios
      .post(url + "api/view/setting/data", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.output)
          setDefaultPrice(response.data.output.premium_package_price);
          setMonthlyPrice(response.data.output.premium_package_monthly_price);
          setYearlyPrice(response.data.output.premium_package_annual_price);
          setDefaultDollarPrice(response.data.output.premium_package_dolar_price);
          setMonthlyDollarPrice(response.data.output.premium_package_monthly_dolar_price);
          setYearlyDollarPrice(response.data.output.premium_package_annual_dolar_price);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  const closeModal = () => {
    setUpdateOpen(false);
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Subscriptions" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setUpdateOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            Update
          </button>
        </div>
      </div>

      <Modal
        isOpen={isUpdateOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Update"
      >
        <SubscriptionUpdate
          fetchData={getSubscriptionData}
          toastPopup={toastPopup}
          closeModal={closeModal}
          data={data}
        />
      </Modal>

      <div className="flex flex-row overflow-x-auto relative shadow-md sm:rounded-lg p-8">
        <div className="flex flex-col w-1/3 rounded-3xl shadow-md m-4 items-center p-2 hover:bg-slate-100">
          <p className="text-2xl font-extrabold tracking-tight text-yellow-600 cursor-default">
            Premium
          </p>
          <p className="text-lg font-bold tracking-tight cursor-default">
            Default
          </p>
          <div className="my-8 items-center text-center">
            <p className="text-2xl font-extrabold tracking-tight cursor-default">
              LKR
              <br />
              {defaultPrice.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })}
            </p>
            <p className="text-2xl font-extrabold tracking-tight cursor-default mt-4">
              $
              <br />
              {defaultDollarPrice.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-1/3 rounded-3xl shadow-md m-4 items-center p-2 hover:bg-slate-100">
          <p className="text-2xl font-extrabold tracking-tight text-green-900 cursor-default">
            Premium
          </p>
          <p className="text-lg font-bold tracking-tight cursor-default">
            Monthly
          </p>
          <div className="my-8 items-center text-center">
            <p className="text-2xl font-extrabold tracking-tight cursor-default">
              LKR
              <br />
              {monthlyPrice.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })}
            </p>
            <p className="text-2xl font-extrabold tracking-tight cursor-default mt-4">
              $
              <br />
              {monthlytDollarPrice.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-1/3 rounded-3xl shadow-md m-4 items-center p-2 hover:bg-slate-100">
          <p className="text-2xl font-extrabold tracking-tight text-blue-600 cursor-default">
            Premium
          </p>
          <p className="text-lg font-bold tracking-tight cursor-default">
            Yealy
          </p>
          <div className="my-8 items-center text-center">
            <p className="text-2xl font-extrabold tracking-tight cursor-default">
              LKR
              <br />
              {yearlyPrice.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })}
            </p>
            <p className="text-2xl font-extrabold tracking-tight cursor-default mt-4">
              $
              <br />
              {yearlytDollarPrice.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscriptions;
