import { LinearProgress } from "@mui/material";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useState } from "react";
import { FormValidator } from "@syncfusion/ej2-react-inputs";
import axios from "axios";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";
import { useEffect } from "react";

const PremiumSubQuestionEdit = (props) => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");
  const user_id = sessionStorage.getItem("user_id");

  const [qType, setQType] = useState(props.question.question_type_id);
  const [qMode, setQMode] = useState(props.question.question_mode_id);
  const [aMode, setAMode] = useState(props.question.answer_mode_id);
  const [points, setPoints] = useState(props.question.point);
  const [file, setFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [fileExtension2, setFileExtension2] = useState(null);
  const [file2, setFile2] = useState(null);
  const [IsShuffle, setIsShuffle] = useState(props.question.is_answer_rotate);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const option = {
    rules: {
      "title-value": {
        required: true,
      },
    },
  };

  let xx = new FormValidator("#myForm", option);

  const editPremiumSubQuestion = async (e) => {
    e.preventDefault();

    const form = document.forms.myForm;
    const formData = new FormData(form);
    const title = formData.get("title-value");
    const description = formData.get("desc-value");

    if (title === "") {
      props.toastPopup(3, "Question Title required!");
    } else {
      let data = new FormData();
      if (qMode === 1) {
        data.append("question_id", props.question.question_id);
        data.append("sub_question_id", props.question.sub_question_id);
        data.append("question_title", title);
        data.append("description", description);
        data.append("question_type_id", qType);
        data.append("question_mode_id", qMode);
        data.append("answer_mode_id", aMode);
        data.append("question_category_id", 1);
        data.append("point", points);
        data.append("file_extension", null);
        data.append("question_file", null);
        data.append("updated_by", user_id);
        data.append("is_answer_rotate", IsShuffle);
        data.append("file_extension2", fileExtension2);
        data.append("question_image_path", file2);
      } else {
        data.append("question_id", props.question.question_id);
        data.append("sub_question_id", props.question.sub_question_id);
        data.append("question_title", title);
        data.append("description", description);
        data.append("question_type_id", qType);
        data.append("question_mode_id", qMode);
        data.append("answer_mode_id", aMode);
        data.append("question_category_id", 1);
        data.append("point", points);
        data.append("file_extension", fileExtension);
        data.append("question_file", file);
        data.append("updated_by", user_id);
        data.append("is_answer_rotate", IsShuffle);
        data.append("file_extension2", fileExtension2);
        data.append("question_image_path", file2);
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress);
        },
      };

      if (qMode === 1) {
        await axios
          .post(url + "api/sub/question/premium/edit", data, config)
          .then((response) => {
            if (response?.status === 200) {
              if (response.data.success) {
                props.toastPopup(1, response.data.message);
              } else {
                props.toastPopup(3, response.data.message);
              }
            } else {
              props.toastPopup(3, response.data.message);
            }
          })
          .catch((error) => {
            props.toastPopup(2, error.message);
          })
          .finally(() => {
            props.loadSubQuestion();
            props.closeModal(4);
          });
      } else {
        await axios
          .post(url + "api/sub/question/premium/edit", data, config)
          .then((response) => {
            if (response?.status === 200) {
              if (response.data.success) {
                props.toastPopup(1, response.data.message);
              } else {
                props.toastPopup(3, response.data.message);
              }
            } else {
              props.toastPopup(3, response.data.message);
            }
          })
          .catch((error) => {
            props.toastPopup(2, error.message);
          })
          .finally(() => {
            props.loadSubQuestion();
            props.closeModal(4);
          });
      }
    }
  };
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setFileExtension(e.target.files[0].name.split(".")[1]);
  };
  const fileHandler2 = (e) => {
    setFile2(e.target.files[0]);
    setFileExtension2(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          Edit Sub Question
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(4);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div>
        <form className="w-full" id="myForm" onSubmit={editPremiumSubQuestion}>
          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full md:w-1/3 px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Question Type
                </label>
                <select
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="question-type"
                  value={qType}
                  onChange={(e) => {
                    setQType(e.target.value);
                    if (e.target.value === "1") {
                      setIsShuffle(1);
                    } else {
                      setIsShuffle(0);
                    }
                  }}
                >
                  <option value={1}>Reading Question</option>
                  <option value={2}>Listening Question</option>
                </select>
              </div>
            </div>
            <div className=" w-full md:w-1/3 px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Question Mode
                </label>
                <select
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="question-mode"
                  value={qMode}
                  onChange={(e) => {
                    setQMode(parseInt(e.target.value));
                  }}
                >
                  <option value={1}>Text</option>
                  <option value={2}>Image</option>
                  <option value={3}>Audio</option>
                  <option value={4}>Video</option>
                  <option value={5}>Image & Text</option>
                </select>
              </div>
            </div>
            {/* Radio box */}
            <div className="w-1/3 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Shuffle Question
              </label>
              <div className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <input
                  id="yes"
                  name="shuffle"
                  type="radio"
                  value="Yes"
                  checked={IsShuffle === 1}
                  onChange={(e) => {
                    setIsShuffle(e.target.checked ? 1 : 0);
                  }}
                />
                <label for="html" className="px-2">
                  Yes
                </label>
                &nbsp;
                <input
                  id="no"
                  name="shuffle"
                  type="radio"
                  checked={IsShuffle === 0}
                  value="No"
                  onChange={(e) => {
                    setIsShuffle(e.target.checked ? 0 : 1);
                  }}
                />
                <label for="css" className="px-2">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Answer Mode
              </label>
              <select
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="answer-mode"
                value={aMode}
                onChange={(e) => {
                  setAMode(e.target.value);
                }}
              >
                <option value={1}>Text</option>
                <option value={2}>Image</option>
                <option value={3}>Audio</option>
                <option value={4}>Video</option>
                <option value={5}>Image & Text</option>
              </select>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Points
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="question-category"
                type="number"
                value={points}
                required
                onChange={(e) => {
                  setPoints(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Title
              </label>
              <RichTextEditorComponent
                id="title"
                htmlAttributes={{ name: "title-value" }}
                value={props.question.question_title}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description
              </label>
              <RichTextEditorComponent
                id="desc"
                htmlAttributes={{ name: "desc-value" }}
                value={props.question.question_description}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          {/* required image */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <lable
                className="block uppercase tracking tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="gridp-last-name"
              >
                Image
              </lable>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                onChange={fileHandler2}
                htmlAttributes={"required=true"}
              />
            </div>
          </div>
          <div className={qMode === 1 ? "hidden" : "flex flex-wrap -mx-3 mb-6"}>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                File &nbsp;
                <label
                  className={
                    qMode === 2 || qMode === 5
                      ? "visible text-red-500"
                      : "hidden"
                  }
                >
                  (image/*)
                </label>
                <label
                  className={qMode === 3 ? "visible text-red-500" : "hidden"}
                >
                  (mp3/*)
                </label>
                <label
                  className={qMode === 4 ? "visible text-red-500" : "hidden"}
                >
                  (mp4/*)
                </label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                onChange={fileHandler}
                accept={
                  qMode === 2 || qMode === 5
                    ? "image/*"
                    : qMode === 3
                    ? "audio/*"
                    : qMode === 4
                    ? "video/*"
                    : ""
                }
                htmlAttributes={"required=true"}
              />
            </div>
          </div>
          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(4);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Update
            </button>
          </div>
        </form>
        <br />
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </div>
  );
};
export default PremiumSubQuestionEdit;
