import axios from "axios";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import Logo from "../data/logo.png";
import { Puff } from "react-loader-spinner";
import { toast } from "react-toastify";

import BGImg from "../data/welcome-bg.svg";

const Login = () => {
  const { url } = useStateContext();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem("token"));

  useEffect(() => {
    if (token === null) {
      setLoading(false);
    } else {
      checkToken();
    }
  }, []);

  const checkToken = () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      key: "value",
    };

    axios
      .post(url + "api/auth/user", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          setLoading(true);
          window.location.replace("/dashboard");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          toast.warning(error.response.data.message);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(error.response.data.message);
        }
      });
  };

  const login = async (e) => {
    e.preventDefault();

    setLoading(true);

    let formData = new FormData();
    formData.append("email_address", email);
    formData.append("password", password);

    await axios
      .post(url + "api/admin/login", formData)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            setError(null);
            setLoading(false);
            setToken(response.data.output);
            sessionStorage.setItem("token", response.data.output);
            getUserData(response.data.output);
          } else {
            setLoading(false);
            setError(response.data.message);
          }
        } else {
          setLoading(false);
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  const getUserData = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      key: "value",
    };

    await axios
      .post(url + "api/auth/user", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          sessionStorage.setItem(
            "user",
            response.data.output.full_name.split(" ")[0]
          );
          sessionStorage.setItem("id", response.data.output.user_id);
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      })
      .finally(() => {
        window.location.replace("/dashboard");
      });
  };

  return (
    // <section className="h-screen">
    //     <div className="px-6 h-full text-gray-800">
    //         <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6" >
    //             <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0" >
    //                 <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" className="w-full" alt="Sample image" />
    //             </div>
    //             <div className="xl:ml-20 xl:w-1/4 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
    //                 <form>
    //                     <div className="flex flex-row items-center justify-center">
    //                         <img src={Logo} alt='logo' classNameName='w-36'/>
    //                     </div>

    //                     <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
    //                         <p className="text-center font-semibold mx-4 mb-0"></p>
    //                     </div>

    //                     <div className="mb-6">
    //                         <input type="text"
    //                             className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
    //                             id="exampleFormControlInput2" placeholder="Email address" />
    //                     </div>

    //                     <div className="mb-6">
    //                         <input type="password"
    //                             className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
    //                             id="exampleFormControlInput2" placeholder="Password" />
    //                     </div>

    //                     <div className="flex justify-between items-center mb-6">
    //                         <div className="form-group form-check">
    //                             <input type="checkbox"
    //                                 className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
    //                                 id="exampleCheck2" />
    //                             <label className="form-check-label inline-block text-gray-800" for="exampleCheck2">Remember me</label>
    //                         </div>
    //                         <a href="#!" className="text-gray-800">Forgot password?</a>
    //                     </div>

    //                     <div className="text-center lg:text-left">
    //                         <button type="button"
    //                             className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
    //                             Login
    //                         </button>
    //                     </div>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    // </section>

    <section className="bg-gray-50 dark:bg-gray-900">
      {loading ? (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div class="mt-3 flex -space-x-20 overflow-hidden">
            <img
              class="inline-block h-20 w-20 rounded-full ring-2 ring-white"
              src={Logo}
              alt=""
            />
            <Puff
              height="80"
              width="80"
              radisu={1}
              color="#2f9dd7"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="flex items-center mb-6 text-3xl font-extrabold text-gray-900 dark:text-white">
            <img className="w-8 h-8 mr-2" src={Logo} alt="logo" />
            CBT &nbsp;
            <p className="font-semibold">Line</p>
          </div>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 z-10">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <div className={``}></div>
              <form className="space-y-4 md:space-y-6" onSubmit={login}>
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required
                  />
                </div>
                <div>
                  <label
                    for="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div></div>
                  <a
                    href="#"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </a>
                </div>

                {error === null ? (
                  <div></div>
                ) : (
                  <div className="text-sm text-red-600 text-center border-2 border-dashed">
                    {error}
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>

          <img src={BGImg} alt="ng" className="absolute right-0 bottom-0" />
        </div>
      )}
    </section>
  );
};
export default Login;
