import { EditUser, Header, Pagination } from "../components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import DefAvatar from "../data/avatar.png";
import StatusLabel from "../components/StatusLabel";
import StatusUpdate from "../components/StatusUpdate";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";
import UserType from "../components/UserType";
import { Divider, Table } from "antd";
import Search from "antd/es/transfer/search";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "60%",
    width: "auto",
    maxWidth: "80%",
  },
};

const columns = [
  {
    key: 1,
    title: "#",
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
  },
  {
    key: 2,
    title: "Profile",
    dataIndex: "profile",
  },
  {
    key: 3,
    title: "Full Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    key: 4,
    title: "Email",
    dataIndex: "email",
    sorter: (a, b) => a.email.length - b.email.length,
  },
  {
    key: 5,
    title: "Country",
    dataIndex: "country",
    sorter: (a, b) => a.country - b.country,
  },
  {
    key: 6,
    title: "Account Type",
    dataIndex: "type",
    sorter: (a, b) =>
      a.status.props.children.props.status -
      b.status.props.children.props.status,
  },
  {
    key: 7,
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) =>
      a.status.props.children.props.status -
      b.status.props.children.props.status,
  },
  {
    key: 8,
    title: "Actions",
    dataIndex: "actions",
  },
];

const Users = () => {
  const { url } = useStateContext();
  const [usersData, setUsersData] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState();

  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    getData(token);
  }, []);

  const getData = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      user_id: 0,
      user_type_id: 1,
      search_data: null,
      status: 1
    };

    await axios
      .post(url + "api/users", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setUsersData(response.data.output);
          setData(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  const setData = (users) => {
    var arr = [];
    users.reverse().forEach((user, x) => {
      let data = {
        id: x + 1,
        profile: (
          <>
            <img
              src={
                user.profile_picture === null ? DefAvatar : user.profile_picture
              }
              alt="profile"
              className="w-16  rounded-[50%]"
            />
          </>
        ),
        name: user.full_name,
        email: user.email_address,
        country: user.mobile_number,
        type: (
          <>
            <UserType id={user.account_type_id} />
          </>
        ),
        status: (
          <>
            <StatusLabel status={user.is_active} />
          </>
        ),
        actions: (
          <>
            <StatusUpdate
              user_id={user.user_id}
              getData={getData}
            />
            <button
              className="bg-yellow-700 rounded-lg text-white w-12 mx-2 text-sm font-thin py-1 px-1"
              onClick={() => {
                openDialog(user);
              }}
            >
              Edit
            </button>
          </>
        ),
      };
      arr.push(data);
    });
    setUsers(arr);
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const closeModal = () => {
    setIsEditUserOpen(false);
  };

  const openDialog = (user) => {
    setEditUser(user);
    setIsEditUserOpen(true);
  };

  const search = (text) => {
    let filtered = usersData.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    );

    setData(filtered);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <Header category="User Management" title="Users" />
          <div className="flex flex-row gap-4">
            <div>
              <Search
                placeholder="Search..."
                onChange={(e) => {
                  setSearchText(e.target.value);
                  search(e.target.value);
                }}
                value={searchText}
                style={{ width: 200 }}
              />
            </div>
          </div>
        </div>
        <div>
          <Table columns={columns} dataSource={users} />
        </div>
      </div>
      <Modal
        isOpen={isEditUserOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit User"
      >
        <EditUser
          fetchData={getData}
          toastPopup={toastPopup}
          closeModal={closeModal}
          user={editUser}
        />
      </Modal>
    </div>
  );
};
export default Users;
