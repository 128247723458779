import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const LiveExamQuestion = (props) => {
  const token = sessionStorage.getItem("token");
  const { url } = useStateContext();
  const [readingQuestions, setReadingQuestions] = useState([]);
  const [listningQuestions, setListningQuestions] = useState([]);

  const [searchData, setSearchData] = useState(null);
  const [qCategory, setQCategory] = useState(0);
  const [searchData1, setSearchData1] = useState(null);
  const [qCategory1, setQCategory1] = useState(0);

  const [selectedReadingQuestions, setSelectedReadingQuestions] = useState([]);
  const [selectedListingQuestions, setSelectedListingQuestions] = useState([]);

  const [isReadingSelectionEnable, setReadingSelectionEnable] = useState(true);
  const [isListningSelectionEnable, setListningSelectionEnable] =
    useState(true);

  useEffect(() => {
    getReadingQuestionData(0, null);
    getListingQuestionData(0, null);
  }, []);

  const getReadingQuestionData = async (qCategory, searchData) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
      question_type_id: 1,
      question_mode_id: 0,
      question_category_id: qCategory,
      search_data: searchData,
      question_id: 0,
    };
    await axios
      .post(url + "api/question/premium/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setReadingQuestions(response.data.output);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const getListingQuestionData = async (qCategory1, searchData1) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
      question_type_id: 2,
      question_mode_id: 0,
      question_category_id: qCategory1,
      search_data: searchData1,
      question_id: 0,
    };

    await axios
      .post(url + "api/question/premium/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setListningQuestions(response.data.output);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const [selectedReadingQCount, setSelectedReadingQCount] = useState(0);
  const [selectedListningQCount, setSelectedListeningQCount] = useState(0);

  const SelectReadingQuestion = (e, row, id) => {
    setReadingSelectionEnable(false);

    if (document.getElementById("R" + id).checked) {
      document.getElementById("R" + id).checked = false;
      setSelectedReadingQCount(selectedReadingQCount - 1);
      removeUSReadingQuestions(row);
    } else {
      if (selectedReadingQCount < props.rQCount) {
        document.getElementById("R" + id).checked = true;
        setSelectedReadingQCount(selectedReadingQCount + 1);
        setSelectedReadingQuestions(current => [...current, row]);
      } else {
        props.toastPopup(3, "You already reached the question limit!");
      }
    }

    setReadingSelectionEnable(true);
  };

  const removeUSReadingQuestions = (row) => {
    selectedReadingQuestions.forEach((data, i) => {
      if (row.question_id === data.question_id) {
        selectedReadingQuestions.splice(selectedReadingQuestions.indexOf(i), 1);
      }
    });
  };

  const SelectListningQuestion = (e, row, id) => {
    setListningSelectionEnable(false);

    if (document.getElementById("L" + id).checked) {
      document.getElementById("L" + id).checked = false;
      setSelectedListeningQCount(selectedListningQCount - 1);
      removeUSListingQuestions(row);
    } else {
      if (selectedListningQCount < props.lQCount) {
        document.getElementById("L" + id).checked = true;
        setSelectedListeningQCount(selectedListningQCount + 1);
        setSelectedListingQuestions(current => [...current, row])
      } else {
        props.toastPopup(3, "You already reached the question limit!");
      }
    }

    setListningSelectionEnable(true);
  };

  const removeUSListingQuestions = (row) => {
    selectedListingQuestions.forEach((data, i) => {
      if (row.question_id === data.question_id) {
        selectedListingQuestions.splice(selectedListingQuestions.indexOf(i), 1);
      }
    });
  };

  let questions = [];
  let isDisable = false;

  const AddQuestion = async () => {
    isDisable = true;

    if (
      selectedReadingQuestions.length !== props.rQCount ||
      selectedListingQuestions.length !== props.lQCount
    ) {
      props.toastPopup(
        3,
        "Select " +
          props.rQCount +
          " Questions for Each Reading & Listening Questions"
      );
    } else {
      await rotator(selectedReadingQuestions, 1);
      await rotator(selectedListingQuestions, 21);

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        user_id: sessionStorage.getItem("id"),
        exam_id: props.id,
        question_data: questions,
      };

      await axios
        .post(url + "api/exam/live/question/manage", bodyParameters, config)
        .then((response) => {
          if (response.data.success) {
            props.toastPopup(1, response.data.message);
            props.closeModal();
            props.fetchData();
            isDisable = false;
          } else {
            props.toastPopup(3, response.data.message);
            props.closeModal();
            isDisable = false;
          }
        })
        .catch((error) => {
          props.toastPopup(2, error.message);
        });
    }
  };

  const rotator = async (questionArray, startingCount) => {
    questionArray.forEach((question, x) => {
      questions.push({
        question_number: x + startingCount,
        question_type_id: 1,
        question_id: question.question_id,
      });
    });
  };

  return (
    <div>
      <div>
        <p className="font-bold text-left text-lg dark:text-gray-200">
          Add Live Exam Questions
        </p>
      </div>

      <div className="flex justify-between items-center float-right">
        <button
          type="button"
          onClick={() => {
            props.closeModal();
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div className="flex justify-between items-center mt-5 w-full text-center ml-0 mr-0">
        <div className="bg-[#33373E] p-3 w-full mr-3 ml-3 hover:drop-shadow-xl rounded-lg text-white">
          Reading Questions <br /> <span>{selectedReadingQCount}</span>
        </div>
        <div className="bg-[#33373E] p-3 w-full mr-3 ml-3 hover:drop-shadow-xl rounded-lg text-white">
          Listening Questions <br /> <span>{selectedListningQCount}</span>
        </div>
      </div>

      <div className="h-[75%] overflow-scroll">
        <ul
          class="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
          id="tabs-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <a
              href="#tabs-home"
              class="nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
              id="tabs-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#tabs-home"
              role="tab"
              aria-controls="tabs-home"
              aria-selected="true"
            >
              Reading Questions
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              href="#tabs-profile"
              class="nav-link block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
              id="tabs-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#tabs-profile"
              role="tab"
              aria-controls="tabs-profile"
              aria-selected="false"
            >
              Listening Question
            </a>
          </li>
        </ul>
        <div class="tab-content" id="tabs-tabContent">
          <div
            class="tab-pane fade show active"
            id="tabs-home"
            role="tabpanel"
            aria-labelledby="tabs-home-tab"
          >
            <div>
              <div className="flex flex-row">
                {/* search bar */}
                <div className="w-1/2 ml-4 mr-4">
                  <div className="flex space-x-4">
                    <div className="flex rounded-md overflow-hidden w-full h-10">
                      <input
                        type="text"
                        className="w-full rounded-md rounded-r-none placeholder-gray-600 border"
                        value={searchData}
                        onChange={(e) => {
                          setSearchData(e.target.value);
                          getReadingQuestionData(qCategory, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* dropdown */}
                <div className="w-1/2 ml-4 mr-4">
                  <select
                    id="countries"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  placeholder-gray-600"
                    value={qCategory}
                    onChange={(e) => {
                      setQCategory(e.target.value);
                      getReadingQuestionData(e.target.value, searchData);
                    }}
                  >
                    <option selected>Question Category</option>
                    <option value={0}>All</option>
                    <option value={1}>Easy</option>
                    <option value={2}>Normal</option>
                    <option value={3}>Hard</option>
                    <option value={4}>Extra Hard</option>
                  </select>
                </div>
              </div>

              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th></th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">Id</div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Question Title
                      </div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Description
                      </div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Question Mode
                      </div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Question Type
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={
                      readingQuestions.length !== 0
                        ? "hidden"
                        : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    }
                  >
                    <td
                      colSpan={4}
                      className="py-4 px-6 text-center justify-center"
                    >
                      No Reading Questions
                    </td>
                  </tr>
                  {readingQuestions.map((question, x) => {
                    return (
                      <tr
                        className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        onClick={
                          isReadingSelectionEnable
                            ? (e) => {
                                SelectReadingQuestion(e, question, x);
                              }
                            : {}
                        }
                      >
                        <td className="py-4 px-6 text-center justify-center">
                          <input
                            type="checkbox"
                            className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            onClick={
                              isReadingSelectionEnable
                                ? (e) => {
                                    SelectReadingQuestion(e, question, x);
                                  }
                                : {}
                            }
                            id={"R" + x}
                          />
                        </td>
                        <td className="py-4 px-6 cursor-pointer">{x + 1}</td>
                        <td
                          className="py-4 px-6 cursor-pointer"
                          dangerouslySetInnerHTML={{
                            __html: question.question_title,
                          }}
                        ></td>
                        <td
                          className="py-4 px-6 cursor-pointer"
                          dangerouslySetInnerHTML={{
                            __html: question.question_description,
                          }}
                        ></td>
                        <td className="py-4 px-6 cursor-pointer">
                          {question.question_type}
                        </td>
                        <td className="py-4 px-6 cursor-pointer">
                          {question.question_category}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="tabs-profile"
            role="tabpanel"
            aria-labelledby="tabs-profile-tab"
          >
            <div>
              <div className="flex flex-row">
                {/* search bar */}
                <div className="w-1/2 ml-4 mr-4">
                  <div className="flex space-x-4">
                    <div className="flex rounded-md overflow-hidden w-full h-10">
                      <input
                        type="text"
                        className="w-full rounded-md rounded-r-none placeholder-gray-600 border"
                        value={searchData1}
                        onChange={(e) => {
                          setSearchData1(e.target.value);
                          getListingQuestionData(qCategory1, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* dropdown */}
                <div className="w-1/2 ml-4 mr-4">
                  <select
                    id="countries"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  placeholder-gray-600"
                    value={qCategory1}
                    onChange={(e) => {
                      setQCategory1(e.target.value);
                      getListingQuestionData(e.target.value, searchData1);
                    }}
                  >
                    <option selected>Question Category</option>
                    <option value={0}>All</option>
                    <option value={1}>Easy</option>
                    <option value={2}>Normal</option>
                    <option value={3}>Hard</option>
                    <option value={4}>Extra Hard</option>
                  </select>
                </div>
              </div>

              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th></th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">Id</div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Question Title
                      </div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Description
                      </div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Question Mode
                      </div>
                    </th>
                    <th scope="col" className="py-3 px-6">
                      <div className="flex items-center cursor-pointer">
                        Question Type
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className={
                      listningQuestions.length !== 0
                        ? "hidden"
                        : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    }
                  >
                    <td
                      colSpan={4}
                      className="py-4 px-6 text-center justify-center"
                    >
                      No Listening Questions
                    </td>
                  </tr>
                  {listningQuestions.map((question, x) => {
                    return (
                      <tr
                        className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        onClick={
                          isListningSelectionEnable
                            ? (e) => {
                                SelectListningQuestion(e, question, x);
                              }
                            : {}
                        }
                      >
                        <td className="py-4 px-6 text-center justify-center">
                          <input
                            type="checkbox"
                            className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            onClick={
                              isListningSelectionEnable
                                ? (e) => {
                                    SelectListningQuestion(e, question, x);
                                  }
                                : {}
                            }
                            id={"L" + x}
                          />
                        </td>
                        <td className="py-4 px-6 cursor-pointer">{x + 1}</td>
                        <td
                          className="py-4 px-6 cursor-pointer"
                          dangerouslySetInnerHTML={{
                            __html: question.question_title,
                          }}
                        ></td>
                        <td
                          className="py-4 px-6 cursor-pointer"
                          dangerouslySetInnerHTML={{
                            __html: question.question_description,
                          }}
                        ></td>
                        <td className="py-4 px-6 cursor-pointer">
                          {question.question_type}
                        </td>
                        <td className="py-4 px-6 cursor-pointer">
                          {question.question_category}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="sticky bottom-0 flex justify-between items-center mt-5 bg-white z-10 bg-hidden">
        <button
          className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
          onClick={() => {
            props.closeModal();
          }}
        >
          Cancel
        </button>
        &nbsp;
        <button
          className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
          onClick={() => {
            AddQuestion();
          }}
          disabled={isDisable}
        >
          Add
        </button>
      </div>
    </div>
  );
};
export default LiveExamQuestion;
