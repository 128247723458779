import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { Header, Pagination } from "../components";
import Modal from "react-modal";
import AssignUserSupport from "../components/AssignUserSupport";
import SupportReply from "../components/SupportReply";
import SupportStatusLabel from "../components/SupportStatusLable";
import { useStateContext } from "../contexts/ContextProvider";
import SupportView from "../components/SupportView";

ReactModal.setAppElement(document.getElementById("root"));
const customStyles1 = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "auto",
    width: "auto",
    maxWidth: "80%",
  },
};
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "65%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Support = () => {
  const { url } = useStateContext();
  const [supports, setSupports] = useState([]);
  const token = sessionStorage.getItem("token");
  const [selectedSupportId, setSelectedSupportId] = useState();
  const [support, setSupport] = useState();

  const [isAssignUserOpen, isSetAssignUserOpen] = useState(false);
  const [isSupportReplyOpen, isSetSupportReplyOpen] = useState(false);
  const [isSupportViewOpen, isSetSupportViewOpen] = useState(false);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = supports.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(supports.length / recordsPerPage);

  useEffect(() => {
    getSupportData();
  }, []);

  const getSupportData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: -1,
      user_id: 0,
      assigner_id: 0,
      support_id: 0,
    };

    await axios
      .post(url + "api/support/view", bodyParameters, config)
      .then((response) => {
        setSupports(response.data.output);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const supportView = (e, support) => {
    e.preventDefault();
    setSupport(support);
    isSetSupportViewOpen(true);
  };

  const closeModal = () => {
    isSetAssignUserOpen(false);
    isSetSupportReplyOpen(false);
    isSetSupportViewOpen(false);
  };

  const statusChange = async (e, support) => {
    e.preventDefault();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (e.target.value === "2") {
      const bodyParameters = {
        status: 2,
        user_id: sessionStorage.getItem("id"),
        support_id: support.support_id,
      };

      await axios
        .post(url + "api/support/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getSupportData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    } else if (e.target.value === "-1") {
      const bodyParameters = {
        status: -1,
        user_id: sessionStorage.getItem("id"),
        support_id: support.support_id,
      };

      await axios
        .post(url + "api/support/status/change", bodyParameters, config)
        .then((response) => {
          toastPopup(1, response.data.message);
          getSupportData();
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Support" />
        </div>
      </div>

      <Modal
        isOpen={isAssignUserOpen}
        onRequestClose={closeModal}
        style={customStyles1}
        shouldCloseOnOverlayClick={false}
        contentLabel="Assign User"
      >
        <AssignUserSupport
          fetchData={getSupportData}
          toastPopup={toastPopup}
          closeModal={closeModal}
          supportId={selectedSupportId}
        />
      </Modal>
      <Modal
        isOpen={isSupportReplyOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Support Reply"
      >
        <SupportReply
          fetchData={getSupportData}
          toastPopup={toastPopup}
          closeModal={closeModal}
          supportId={selectedSupportId}
        />
      </Modal>

      <Modal
        isOpen={isSupportViewOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Support View"
      >
        <SupportView
          fetchData={getSupportData}
          toastPopup={toastPopup}
          closeModal={closeModal}
          support={support}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Number</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Full Name
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Suppport Type
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Description</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Status</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                supports.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={7} className="py-4 px-6 text-center justify-center">
                No Supports Requests
              </td>
            </tr>
            {currentRecords.map((support, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    className="py-4 px-6 text-center justify-center cursor-pointer"
                    onClick={(e) => {
                      supportView(e, support);
                      isSetSupportViewOpen(true);
                    }}
                  >
                    {indexOfFirstRecord + row}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={(e) => {
                      supportView(e, support);
                      isSetSupportViewOpen(true);
                    }}
                  >
                    {support.full_name}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={(e) => {
                      supportView(e, support);
                      isSetSupportViewOpen(true);
                    }}
                  >
                    {support.support_type}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={(e) => {
                      supportView(e, support);
                      isSetSupportViewOpen(true);
                    }}
                  >
                    {support.title}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={(e) => {
                      supportView(e, support);
                      isSetSupportViewOpen(true);
                    }}
                  >
                    {support.description}
                  </td>
                  <td className="py-4 px-6">
                    <SupportStatusLabel status={support.status} />
                  </td>
                  <td className="py-4 px-6">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedSupportId(support.support_id);
                        isSetAssignUserOpen(true);
                      }}
                      className="p-2 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
                    >
                      Assign User
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setSelectedSupportId(support.support_id);
                        isSetSupportReplyOpen(true);
                      }}
                      className="p-2 hover:drop-shadow-xl bg-[#f04620] text-white rounded-lg my-1"
                    >
                      Reply
                    </button>

                    <select
                      className="appearance-none p-2 block bg-gray-700 text-white border border-gray-200 rounded-xl leading-tight focus:outline-none focus:bg-white focus:text-black focus:border-gray-500 text-center"
                      onChange={(e) => {
                        statusChange(e, support);
                      }}
                      value={support.status}
                    >
                      <option value={1}>Ongoing</option>
                      <option value={2}>Completed</option>
                      <option value={-1}>Rejected</option>
                    </select>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Support;
