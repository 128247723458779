import axios from "axios";
import { useStateContext } from "../../contexts/ContextProvider";
import { Popconfirm } from "antd";

const PremiumQuestionDelete = (props) => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");

  const premiumQustionDelete = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      question_id: props.question_id,
      status: 0,
    };
    await axios
      .post(url + "api/question/premium/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          props.toastPopup(1, response.data.message);
          props.loadQuestions(null, null, 0, 0, null);
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };

  const confirm = () => {
    premiumQustionDelete();
  };
  const cancel = () => {};
  return (
    <>
      <Popconfirm
        title="Delete the Question"
        description="Are you sure to delete this question?"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <button
          className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl"
        >
          Delete
        </button>
      </Popconfirm>
    </>
  );
};
export default PremiumQuestionDelete;
