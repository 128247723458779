import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PremiumSubQuestionAnswerAdd,
  PremiumSubQuestionAnswerEdit,
  PremiumSubQuestionEdit,
  PremiumSubQuestionAdd,
  Pagination,
} from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const PremiumSubQuestionView = () => {
  const { url } = useStateContext();
  let params = useParams();
  const token = sessionStorage.getItem("token");

  const [question, setQuestion] = useState([]);
  const [aMode, setAMode] = useState();
  const [subQuestions, setSubQuestions] = useState([]);

  const [selectedSubQuestion, setSelectedSubQuestion] = useState();

  const [isPremiumSubQuestionAddOpen, setIsPremiumSubQuestionAddOpen] =
    useState(false);
  const [
    isPremiumSubQuestionAnswerAddOpen,
    setIsPremiumSubQuestionAnswerAddOpen,
  ] = useState(false);
  const [
    isPremiumSubQuestionAnswerEditOpen,
    setIsPremiumSubQuestionAnswerEditOpen,
  ] = useState(false);
  const [isPremiumSubQuestionEditOpen, setIsPremiumSubQuestionEditOpen] =
    useState(false);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = subQuestions.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(subQuestions.length / recordsPerPage);

  useEffect(() => {
    loadQuestion();
    loadSubQuestions();
  }, []);

  const closeModal = (id) => {
    if (id === 1) {
      setIsPremiumSubQuestionAddOpen(false);
    } else if (id === 2) {
      setIsPremiumSubQuestionAnswerAddOpen(false);
    } else if (id === 3) {
      setIsPremiumSubQuestionAnswerEditOpen(false);
    } else {
      setIsPremiumSubQuestionEditOpen(false);
    }
  };

  const loadQuestion = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      status: 1,
      question_type_id: 0,
      question_mode_id: 0,
      question_category_id: 0,
      search_data: null,
      question_id: params.question_id,
    };

    await axios
      .post(url + "api/question/premium/all", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          setQuestion(response.data.output[0]);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const loadSubQuestions = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
      question_id: params.question_id,
    };

    await axios
      .post(url + "api/premium/question/all", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          setSubQuestions(response.data.output.questions[0].sub_question);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const deletePremiumSubQuestion = async (qId) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      sub_question_id: qId,
      status: 0,
    };

    await axios
      .post(url + "api/sub/question/premium/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          toastPopup(1, response.data.message);
          loadSubQuestions();
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const addPremiumSubQuestionAnswers = (question) => {
    setSelectedSubQuestion(question);
    setAMode(question.answer_mode_id);
    setIsPremiumSubQuestionAnswerAddOpen(true);
  };

  const editPremiumSubQuestionAnswers = (subquestion) => {
    setSelectedSubQuestion(subquestion);
    setAMode(question.answer_mode_id);
    setIsPremiumSubQuestionAnswerEditOpen(true);
  };

  const editPremiumSubQuestion = (subquestion) => {
    setSelectedSubQuestion(subquestion);
    setIsPremiumSubQuestionEditOpen(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <div>
            <div className=" mb-10">
              <p
                className="text-lg text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: question.question_title,
                }}
              ></p>
              <p
                className="text-2xl font-extrabold tracking-tight text-slate-900"
                dangerouslySetInnerHTML={{
                  __html: question.description,
                }}
              ></p>
              <div className="w-full flex flex-row mt-2">
                <div className="text-center">
                  <label
                    className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                    for="grid-last-name"
                  >
                    {question.question_type}
                  </label>
                </div>
                &nbsp;
                <div className="text-center">
                  <label
                    className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                    for="grid-last-name"
                  >
                    {question.question_category}
                  </label>
                </div>
                &nbsp;
                <div className="text-center">
                  <label
                    className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                    for="grid-last-name"
                  >
                    Points: {question.point}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsPremiumSubQuestionAddOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Sub Question
          </button>
        </div>
      </div>

      <Modal
        isOpen={isPremiumSubQuestionAddOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Sub Question Add"
      >
        <PremiumSubQuestionAdd
          toastPopup={toastPopup}
          question={question.question_id}
          loadSubQuestion={loadSubQuestions}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPremiumSubQuestionAnswerAddOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Sub Question Answer Add"
      >
        <PremiumSubQuestionAnswerAdd
          toastPopup={toastPopup}
          question={selectedSubQuestion?.question_id}
          subquestion={selectedSubQuestion?.sub_question_id}
          answerMode={aMode}
          loadSubQuestion={loadSubQuestions}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPremiumSubQuestionAnswerEditOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Sub Question Answer Edit"
      >
        <PremiumSubQuestionAnswerEdit
          toastPopup={toastPopup}
          question={selectedSubQuestion}
          loadSubQuestion={loadSubQuestions}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPremiumSubQuestionEditOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Sub Question Edit"
      >
        <PremiumSubQuestionEdit
          toastPopup={toastPopup}
          question={selectedSubQuestion}
          loadSubQuestion={loadSubQuestions}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Id
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Mode
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Category
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Points
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Answers
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                subQuestions.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={8} className="py-4 px-6 text-center justify-center">
                No Sub Questions
              </td>
            </tr>
            {currentRecords.map((question, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6 text-center">
                    {indexOfFirstRecord + row}
                  </td>
                  <td
                    className="py-4 px-6"
                    dangerouslySetInnerHTML={{
                      __html: question.question_title,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6"
                    dangerouslySetInnerHTML={{
                      __html: question.question_description,
                    }}
                  ></td>
                  <td className="py-4 px-6 text-center">
                    {question.question_mode}
                  </td>
                  <td className="py-4 px-6 text-center">
                    {question.question_category}
                  </td>
                  <td className="py-4 px-6 text-center">{question.point}</td>
                  <td className="py-4 px-6 text-center">
                    {question.answers === undefined
                      ? 0
                      : question.answers.length}
                  </td>
                  <td className="py-4 px-6 text-center">
                    <button
                      className="bg-green-600 hover:bg-green-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        if (question.answers.length === 0) {
                          addPremiumSubQuestionAnswers(question);
                        } else {
                          editPremiumSubQuestionAnswers(question);
                        }
                      }}
                    >
                      Add/Edit Answers
                    </button>
                    &nbsp;
                    <button
                      className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        editPremiumSubQuestion(question);
                      }}
                    >
                      Edit
                    </button>
                    &nbsp;
                    <button
                      onClick={() => {
                        deletePremiumSubQuestion(question.sub_question_id);
                      }}
                      className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PremiumSubQuestionView;
