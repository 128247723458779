import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";

const DeleteWord = (props) => {
  const { url } = useStateContext();
    const token = sessionStorage.getItem("token");
  
    const deleteWord = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
  
      const bodyParameters = {
        word_id: props.word_id,
        status: 0,
        
      };
  
      await axios
        .post(url + "api/word/delete", bodyParameters, config)
        .then((response) => {
          if (response?.status === 200) {
            props.toastPopup(1, response.data.message);
            props.getWordData(token);
          } else {
            props.toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          props.toastPopup(2, error.message);
        });
    };
  
    return (
      <>
        <button
          onClick={deleteWord}
          className="bg-red-500 hover:bg-red-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl"
        >
          Delete
        </button>
      </>
    );
  };
  export default DeleteWord;