import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Header,
  NewQuestion,
  Pagination,
  PremiumAnswerAdd,
  PremiumAnswerEdit,
  PremiumQuestionDelete,
  PremiumQuestionEdit,
} from "../components";
import PremiumQuestionView from "../components/Premium/PremiumQuestionView";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Questions = () => {
  const { url } = useStateContext();
  let token = sessionStorage.getItem("token");

  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const navigate = useNavigate();

  const [isNewQuestionOpen, setIsNewQuestionOpen] = useState(false);
  const [isPremiumQuestionViewOpen, setIsPremiumQuestionViewOpen] =
    useState(false);
  const [isPremiumQuestionEditOpen, setIsPremiumQuestionEditOpen] =
    useState(false);
  const [isPremiumAnswerAddOpen, setIsPremiumAnswerAddOpen] = useState(false);
  const [isPremiumAnswerEditOpen, setIsPremiumAnswerEditOpen] = useState(false);

  // const for searchbars
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [qCategory, setQCategory] = useState(0);
  const [qType, setQType] = useState(0);
  const [searchData, setSearchData] = useState(null);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(300);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = questions.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(questions.length / recordsPerPage);

  useEffect(() => {
    loadQuestions(null, null, 0, 0, null);
  }, []);

  const closeModal = (id) => {
    if (id === 1) {
      setIsNewQuestionOpen(false);
    } else if (id === 2) {
      setIsPremiumQuestionViewOpen(false);
    } else if (id === 3) {
      setIsPremiumQuestionEditOpen(false);
    } else if (id === 4) {
      setIsPremiumAnswerAddOpen(false);
    } else {
      setIsPremiumAnswerEditOpen(false);
    }
  };

  const loadQuestions = async (
    startDate,
    endDate,
    qCategory,
    qType,
    searchData
  ) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
      question_type_id: qType,
      question_mode_id: 0,
      question_category_id: qCategory,
      search_data: searchData,
      start_date: startDate,
      end_date: endDate,
    };
    await axios
      .post(url + "api/question/premium/search", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          let newArray = response.data.output.questions;
          setQuestions(newArray.reverse());
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  const premiumQuestionView = (question) => {
    setQuestion(question);
    setIsPremiumQuestionViewOpen(true);
  };

  const addPremiumSubQuestions = (question) => {
    navigate(`/question/${question.question_id}`);
  };

  const premiumAnswerEdit = (question) => {
    setSelectedQuestion(question);
    setIsPremiumAnswerEditOpen(true);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Questions" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsNewQuestionOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Question
          </button>
        </div>
      </div>

      {/* Search bars */}
      <div className="flex flex-row">
        <div className="flex flex-col w-1/6 m-1 ">
          <label className="uppercase text-gray-700 text-xs font-bold mb-0">
            Start Date
          </label>
          <input
            type="date"
            className="w-full rounded-lg  mx-1 my-2 border"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              loadQuestions(
                e.target.value,
                endDate,
                qCategory,
                qType,
                searchData
              );
            }}
          />
        </div>

        <div className="flex flex-col w-1/6 m-1">
          <label className="uppercase text-gray-700 text-xs font-bold m-0">
            End Date
          </label>
          <input
            type="date"
            className="w-full rounded-lg mx-1  my-2 border"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              loadQuestions(
                startDate,
                e.target.value,
                qCategory,
                qType,
                searchData
              );
            }}
          />
        </div>

        <div className="flex flex-col w-1/6 m-1 ">
          <label className="uppercase text-gray-700 text-xs font-bold m-0">
            Category
          </label>
          <select
            class="text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border p-1 m-2"
            value={qCategory}
            onChange={(e) => {
              setQCategory(e.target.value);
              loadQuestions(
                startDate,
                endDate,
                e.target.value,
                qType,
                searchData
              );
            }}
          >
            <option selected>Question Category</option>
            <option value={0}>All</option>
            <option value={1}>Easy</option>
            <option value={2}>Normal</option>
            <option value={3}>Hard</option>
            <option value={4}>Extra Hard</option>
          </select>
        </div>

        <div className="flex flex-col w-1/6 m-1 ">
          <label className="uppercase text-gray-700 text-xs font-bold m-0">
            Type
          </label>
          <select
            class="text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border p-1 m-2"
            value={qType}
            onChange={(e) => {
              setQType(e.target.value);
              loadQuestions(
                startDate,
                endDate,
                qCategory,
                e.target.value,
                searchData
              );
            }}
          >
            <option selected>Question Type</option>
            <option value={0}>All</option>
            <option value={1}>Reading Question</option>
            <option value={2}>Listening Question</option>
          </select>
        </div>

        <div className="flex flex-col w-2/6 m-1 ">
          <label className="uppercase text-gray-700 text-xs font-bold m-0">
            Search
          </label>
          <input
            type="text"
            className="w-full rounded-lg mx-1  my-2 border"
            value={searchData}
            onChange={(e) => {
              setSearchData(e.target.value);
              loadQuestions(
                startDate,
                endDate,
                qType,
                qCategory,
                e.target.value
              );
            }}
          />
        </div>
      </div>

      <Modal
        isOpen={isNewQuestionOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Question"
      >
        <NewQuestion
          toastPopup={toastPopup}
          loadQuestions={loadQuestions}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPremiumQuestionViewOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Question View"
      >
        <PremiumQuestionView
          question={question}
          toastPopup={toastPopup}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPremiumQuestionEditOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Question Edit"
      >
        <PremiumQuestionEdit
          toastPopup={toastPopup}
          loadQuestions={loadQuestions}
          question={selectedQuestion}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPremiumAnswerAddOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Answer Add"
      >
        <PremiumAnswerAdd
          question={selectedQuestion}
          toastPopup={toastPopup}
          loadQuestions={loadQuestions}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isPremiumAnswerEditOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Premium Answer Edit"
      >
        <PremiumAnswerEdit
          question={selectedQuestion}
          toastPopup={toastPopup}
          loadQuestions={loadQuestions}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-4">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">ID</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Description</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Mode</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Category</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Type</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer justify-center">
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                questions.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={7} className="py-4 px-6 text-center justify-center">
                No Questions
              </td>
            </tr>

            {currentRecords.map((question, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                  <td className="py-4 px-6">{indexOfFirstRecord + row}</td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      premiumQuestionView(question);
                    }}
                    dangerouslySetInnerHTML={{
                      __html: question.question_title,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      premiumQuestionView(question);
                    }}
                    dangerouslySetInnerHTML={{
                      __html: question.question_description,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      premiumQuestionView(question);
                    }}
                  >
                    {question.question_mode}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      premiumQuestionView(question);
                    }}
                  >
                    {question.question_category}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={() => {
                      premiumQuestionView(question);
                    }}
                  >
                    {question.question_type}
                  </td>
                  <td className="py-4 px-6">
                    <button
                      className="bg-green-600 hover:bg-green-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        if (question.question_category_id === 4) {
                          addPremiumSubQuestions(question);
                        } else {
                          if (question.answers.length === 0) {
                            setSelectedQuestion(question);
                            setIsPremiumAnswerAddOpen(true);
                          } else {
                            premiumAnswerEdit(question);
                          }
                        }
                      }}
                    >
                      {question.question_category_id === 4
                        ? "Add Sub Questions"
                        : "Add/Edit Answers"}
                    </button>
                    &nbsp;
                    <button
                      className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        setSelectedQuestion(question);
                        setIsPremiumQuestionEditOpen(true);
                      }}
                    >
                      Edit
                    </button>
                    &nbsp;
                    <PremiumQuestionDelete
                      question_id={question.question_id}
                      loadQuestions={loadQuestions}
                      toastPopup={toastPopup}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Questions;
