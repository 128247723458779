import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Pagination } from "../components";
import LiveExamQuestions from "../components/LiveExamQuestion";
import { useStateContext } from "../contexts/ContextProvider";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const LiveExamQuestion = () => {
  const token = sessionStorage.getItem("token");
  let params = useParams();
  const { url } = useStateContext();

  const [isQuestionAddOpen, setIsQuestionAddOpen] = useState(false);
  const [examData, setExamData] = useState();
  const [questions, setQuestions] = useState([]);

  const [readingQuestionCount, setReadingQuestionCount] = useState(0);
  const [listeningQuestionCount, setListeningQuestionCount] = useState(0);
  const [examQuestionCount, setExamQuestionCount] = useState(0);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = questions.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(questions.length / recordsPerPage);

  useEffect(() => {
    getLiveExamData();
  }, []);

  const getLiveExamData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      exam_id: params.exam_id,
      question_type_id: 0,
      question_mode_id: 0,
      question_category_id: 0,
      search_data: null,
      question_id: 0,
      status: 1,
    };

    await axios
      .post(url + "api/exam/live/question/all", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setExamQuestionCount(response.data.output.questions.length);
          setExamData(response.data.output.exam);
          setQuestions(response.data.output.questions);
          setReadingQuestionCount(
            response.data.output.exam.reading_question_count
          );
          setListeningQuestionCount(
            response.data.output.exam.listning_question_count
          );
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.text);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else {
      toast.warning(text);
    }
  };

  const closeModal = () => {
    setIsQuestionAddOpen(false);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <div>
            <div className=" mb-10">
              <p
                className="text-lg text-gray-400"
                dangerouslySetInnerHTML={{
                  __html: examData?.description,
                }}
              ></p>
              <p
                className="text-2xl font-extrabold tracking-tight text-slate-900"
                dangerouslySetInnerHTML={{
                  __html: examData?.exam_title,
                }}
              ></p>
            </div>
          </div>
        </div>
        <div className="w-2/4 text-right">
          {examQuestionCount === 0 ? (
            <button
              type="button"
              onClick={() => {
                setIsQuestionAddOpen(true);
              }}
              className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
            >
              Add Questions
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Modal
        isOpen={isQuestionAddOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="New Question"
      >
        <LiveExamQuestions
          id={params.exam_id}
          fetchData={getLiveExamData}
          closeModal={closeModal}
          toastPopup={toastPopup}
          rQCount={readingQuestionCount}
          lQCount={listeningQuestionCount}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">ID</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Description</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Mode</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Category</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">Type</div>
              </th>
              {/* <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer justify-center">
                  Action
                </div>
              </th> */}
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                questions.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={7} className="py-4 px-6 text-center justify-center">
                No Questions
              </td>
            </tr>
            {currentRecords.map((question, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                  <td className="py-4 px-6">{indexOfFirstRecord + row}</td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: question.question_title,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: question.description,
                    }}
                  ></td>
                  <td className="py-4 px-6 cursor-pointer">
                    {question.question_mode}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {question.question_category}
                  </td>
                  <td className="py-4 px-6 cursor-pointer">
                    {question.question_type}
                  </td>
                  {/* <td className="py-4 px-6"></td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default LiveExamQuestion;
