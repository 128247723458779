import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AnswerAdd,
  AnswerEdit,
  Header,
  Pagination,
  QuestionEdit,
} from "../components";
import DeleteQuestion from "../components/DeleteQuestion";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";
import QuestionAdd from "../components/QuestionAdd";
import QuestionView from "../components/QuestionView";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "100%",
    width: "auto",
    maxWidth: "80%",
  },
};

const ExamView = () => {
  const token = sessionStorage.getItem("token");
  let params = useParams();
  const navigate = useNavigate();
  const [examviews, setExamView] = useState([]);
  const [examId, setExamId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const { url } = useStateContext();

  const [questionId, setQuestionId] = useState();
  const [question, setQuestion] = useState();

  const [isQuestionAddOpen, setIsQuestionAddOpen] = useState(false);
  const [isQuestionViewOpen, setIsQuestionViewOpen] = useState(false);
  const [isAnswerAddOpen, setIsAnswerAddOpen] = useState(false);
  const [isAnswerEditOpen, setIsAnswerEditOpen] = useState(false);
  const [isQuestionEditOpen, setIsQuestionEditOpen] = useState(false);

  // const for searchbars
  const [qCategory, setQCategory] = useState(0);
  const [qType, setQType] = useState(0);
  const [searchData, setSearchData] = useState(null);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = examviews.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(examviews.length / recordsPerPage);

  useEffect(() => {
    getExamData(0, 0, null);
  }, []);

  const getExamData = async (qCategory, qType, searchData) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      exam_id: params.exam_id,
      question_type_id: qType,
      question_mode_id: 0,
      question_category_id: qCategory,
      search_data: searchData,
      question_id: 0,
      status: 1,
    };

    await axios
      .post(url + "api/question/search", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setExamId(response.data.output.exams[0].exam_id);
          setTitle(response.data.output.exams[0].exam_title);
          setDescription(response.data.output.exams[0].exam_description);
          let newArray = response.data.output.exams[0].questions;
          setExamView(newArray.reverse());
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.text);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };
  const questionView = (e, question_id) => {
    e.preventDefault();
    setQuestionId(question_id);
    setIsQuestionViewOpen(true);
  };

  const questionEditView = async (examview) => {
    setQuestionId(examview.question_id);
    setQuestion(examview);
    setIsAnswerEditOpen(true);
  };

  const addSubQuestions = (examview) => {
    navigate(`/exam/${examId}/${examview.question_id}`);
  };

  const EditQuestion = (examview) => {
    setQuestion(examview);
    setIsQuestionEditOpen(true);
  };

  const closeModal = (id) => {
    if (id === 1) {
      setIsQuestionAddOpen(false);
    } else if (id === 2) {
      setIsQuestionViewOpen(false);
    } else if (id === 3) {
      setIsAnswerAddOpen(false);
    } else if (id === 4) {
      setIsAnswerEditOpen(false);
    } else {
      setIsQuestionEditOpen(false);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category={description} title={title} />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={() => {
              setIsQuestionAddOpen(true);
            }}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Question
          </button>
        </div>
      </div>

      {/* Search bars */}
      <div className="flex flex-row">
        <div className="flex flex-col w-1/3 m-1 ">
          <label className="uppercase text-gray-700 text-xs font-bold m-0">
            Category
          </label>
          <select
            class="text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border p-2.5 m-2"
            value={qCategory}
            onChange={(e) => {
              setQCategory(e.target.value);
              getExamData(e.target.value, qType, searchData);
            }}
          >
            <option selected>Question Category</option>
            <option value={0}>All</option>
            <option value={1}>Easy</option>
            <option value={2}>Normal</option>
            <option value={3}>Hard</option>
            <option value={4}>Extra Hard</option>
          </select>
        </div>

        <div className="flex flex-col w-1/3 m-1 ">
          <label className="uppercase text-gray-700 text-xs font-bold m-0">
            Type
          </label>
          <select
            class="text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border p-2.5 m-2"
            value={qType}
            onChange={(e) => {
              setQType(e.target.value);
              getExamData(qCategory, e.target.value, searchData);
            }}
          >
            <option selected>Question Type</option>
            <option value={0}>All</option>
            <option value={1}>Reading Question</option>
            <option value={2}>Listening Question</option>
          </select>
        </div>

        <div className="flex flex-col w-2/3 m-1 ">
          <label className="uppercase text-gray-700 text-xs font-bold m-0">
            Search
          </label>
          <input
            type="text"
            className="w-full rounded-lg mx-1  my-2 border p-2"
            value={searchData}
            onChange={(e) => {
              setSearchData(e.target.value);
              getExamData(qType, qCategory, e.target.value);
            }}
          />
        </div>
      </div>

      <Modal
        isOpen={isQuestionAddOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Question Add"
      >
        <QuestionAdd
          id={params.exam_id}
          toastPopup={toastPopup}
          getExamData={getExamData}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isQuestionViewOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Question View"
      >
        <QuestionView
          id={questionId}
          exam_id={params.exam_id}
          toastPopup={toastPopup}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isAnswerAddOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Answer Add"
      >
        <AnswerAdd
          exam_id={params.exam_id}
          questionId={questionId}
          toastPopup={toastPopup}
          getExamData={getExamData}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isAnswerEditOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Answer Edit"
      >
        <AnswerEdit
          exam_id={params.exam_id}
          questionId={questionId}
          toastPopup={toastPopup}
          getExamData={getExamData}
          question={question}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={isQuestionEditOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Question Edit"
      >
        <QuestionEdit
          toastPopup={toastPopup}
          getExamData={getExamData}
          question={question}
          closeModal={closeModal}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Id
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">Title</div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer">
                  Description
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Mode
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Category
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Type
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Points
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div className="flex items-center cursor-pointer justify-center">
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                examviews.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={8} className="py-4 px-6 text-center justify-center">
                No Questions
              </td>
            </tr>
            {currentRecords.map((examview, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    className="py-4 px-6 text-center cursor-pointer"
                    onClick={(e) => {
                      questionView(e, examview.question_id);
                    }}
                  >
                    {indexOfFirstRecord + row}
                  </td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={(e) => {
                      questionView(e, examview.question_id);
                    }}
                    dangerouslySetInnerHTML={{
                      __html: examview.question_title,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 cursor-pointer"
                    onClick={(e) => {
                      questionView(e, examview.question_id);
                    }}
                    dangerouslySetInnerHTML={{
                      __html: examview.question_description,
                    }}
                  ></td>
                  <td
                    className="py-4 px-6 text-center cursor-pointer"
                    onClick={(e) => {
                      questionView(e, examview.question_id);
                    }}
                  >
                    {examview.question_mode}
                  </td>
                  <td
                    className="py-4 px-6 text-center cursor-pointer"
                    onClick={(e) => {
                      questionView(e, examview.question_id);
                    }}
                  >
                    {examview.question_category}
                  </td>
                  <td
                    className="py-4 px-6 text-center cursor-pointer"
                    onClick={(e) => {
                      questionView(e, examview.question_id);
                    }}
                  >
                    {examview.question_type}
                  </td>
                  <td
                    className="py-4 px-6 text-center cursor-pointer"
                    onClick={(e) => {
                      questionView(e, examview.question_id);
                    }}
                  >
                    {examview.point}
                  </td>
                  <td className="py-4 px-6 text-center">
                    <button
                      className="bg-green-600 hover:bg-green-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        if (examview.question_category_id === 4) {
                          addSubQuestions(examview);
                        } else {
                          if (examview.answers.length === 0) {
                            setQuestionId(examview.question_id);
                            setIsAnswerAddOpen(true);
                          } else {
                            questionEditView(examview);
                          }
                        }
                      }}
                    >
                      {examview.question_category_id === 4
                        ? "Add Sub Questions"
                        : "Add/Edit Answers"}
                    </button>
                    &nbsp;
                    <button
                      className="bg-yellow-600 hover:bg-yellow-900 text-white text-center text-sm font-thin py-1 px-3 rounded-3xl my-2"
                      onClick={() => {
                        EditQuestion(examview);
                      }}
                    >
                      Edit
                    </button>
                    &nbsp;
                    <DeleteQuestion
                      question_id={examview.question_id}
                      getExamData={getExamData}
                      toastPopup={toastPopup}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default ExamView;
