import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from ".";
import { userProfileData } from "../data/dummy";
import avatar from "../data/avatar.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";


const UserProfile = () => {
  const { url } = useStateContext();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const { setIsClicked, initialState } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    getUserData(token);
  }, []);

  const getUserData = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      key: "value",
    };

    await axios
      .post(url + "api/auth/user", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          setEmail(response.data.output.email_address);
          setName(response.data.output.full_name);
          setType(response.data.output.user_type);
          setProfilePicture(response.data.output.profile_picture);
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  const logout = () => {
    sessionStorage.clear();
    window.location.replace("/login");
  };
const viewMyprofile=()=>{
  navigate(`/myprofile/`);
  
}

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={profilePicture ? profilePicture : avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">{name}</p>
          <p className="text-gray-500 text-sm dark:text-gray-400">{type}</p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {email}
          </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
            onClick={() => {
              viewMyprofile();
              setIsClicked(initialState);
            }}
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>
            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {item.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <button
          type="button"
          className="p-3 w-full hover:drop-shadow-xl bg-[#1E4DB7] hover:bg-[#123588] rounded-lg text-white"
          onClick={logout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
