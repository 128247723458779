import React, { useEffect, useState } from "react";

import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { MdQuestionAnswer } from "react-icons/md";
import { BiUser, BiUserCheck } from "react-icons/bi";
import { LineChart } from "../components";

const Dashboard = () => {
  const { currentColor, url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));
  const [data, setData] = useState();

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (token === null) {
      window.location.replace("/login");
    } else {
      getUserData(token);
      loadDashboardData();
    }
  }, []);

  const loadDashboardData = () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      user_id: sessionStorage.getItem("id"),
    };

    axios
      .post(url + "api/admin/dashboard/data", bodyParameters, config)
      .then((response) => {
        setGraph(response.data.output.graph_data_user_count);
        setData(response.data.output);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const getUserData = async (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      key: "value",
    };

    axios
      .post(url + "api/auth/user", bodyParameters, config)
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          toast.warning(error.response.data.message);
          window.location.replace("/login");
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const setGraph = (data) => {
    let arr = [];
    data.forEach((dat) => {
      let data = {
        type: dat.created_month,
        sales: dat.user_count,
      };
      arr.push(data);
    });
    setChartData(arr);
  };

  return (
    <div className="mt-24">
      <div className="flex gap-10 flex-wrap justify-center">
        <div className="flex m-3 md:flex-row flex-col justify-center gap-1 items-center w-full">
          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-1/3 p-4 pt-9 rounded-2xl border-1">
            <button
              type="button"
              className="text-2xl bg-amber-400 opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <BiUser />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">
                {data?.total_user_count} Users
              </span>
            </p>
            <p className="text-sm text-gray-400  mt-1">Total user Count</p>
          </div>
          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-1/3 p-4 pt-9 rounded-2xl border-1">
            <button
              type="button"
              className="text-2xl bg-green-400 opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <BiUserCheck />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">
                {data?.premium_account_count} Users
              </span>
            </p>
            <p className="text-sm text-gray-400  mt-1">Premium user Count</p>
          </div>
          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full md:w-1/3 p-4 pt-9 rounded-2xl border-1">
            <button
              type="button"
              className="text-2xl bg-teal-500 opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <MdQuestionAnswer />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">
                {data?.premium_question_count} Questions
              </span>
            </p>
            <p className="text-sm text-gray-400  mt-1">Questions</p>
          </div>
        </div>
      </div>

      <div className="flex gap-10 flex-wrap justify-center">
        <div className="flex m-3 md:flex-row flex-col justify-center gap-1 items-center w-full">
          <div
            className=" rounded-2xl w-full p-4 m-3"
            style={{ backgroundColor: currentColor }}
          >
            <div className="flex justify-between items-center ">
              <p className="font-semibold text-white text-2xl">Users</p>

              <div>
                <p className="text-2xl text-white font-semibold mt-8">
                  {data?.total_user_count} Users
                </p>
                <p className="text-gray-200">Tatal Users</p>
              </div>
            </div>

            <div className="mt-4">
              <LineChart data={chartData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
