import { LinearProgress } from "@mui/material";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useState } from "react";
import { FormValidator } from "@syncfusion/ej2-react-inputs";
import axios from "axios";
import { useEffect } from "react";

const SubQuestionAnswerEdit = (props) => {
  const { url } = useStateContext();
  const token = sessionStorage.getItem("token");
  const [correctAnswerCount, setCorrectAnswerCount] = useState(1);

  const [answerMode, setAnswerMode] = useState(0);
  const [progress, setProgress] = useState(0);
  const [answers, setAnswers] = useState([]);

  const [a1FIle, setA1File] = useState(null);
  const [a1FIleExtension, setA1FileExtension] = useState(null);
  const [a1IsCorrect, setA1IsCorrect] = useState(
    props.question.answers[0]?.is_correct === 1 ? "Yes" : "No"
  );

  const [a2FIle, setA2File] = useState(null);
  const [a2FIleExtension, setA2FileExtension] = useState(null);
  const [a2IsCorrect, setA2IsCorrect] = useState(
    props.question.answers[1]?.is_correct === 1 ? "Yes" : "No"
  );

  const [a3FIle, setA3File] = useState(null);
  const [a3FIleExtension, setA3FileExtension] = useState(null);
  const [a3IsCorrect, setA3IsCorrect] = useState(
    props.question.answers[2]?.is_correct === 1 ? "Yes" : "No"
  );

  const [a4FIle, setA4File] = useState(null);
  const [a4FIleExtension, setA4FileExtension] = useState(null);
  const [a4IsCorrect, setA4IsCorrect] = useState(
    props.question.answers[3]?.is_correct === 1 ? "Yes" : "No"
  );

  const a1FileHandler = (e) => {
    setA1File(e.target.files[0]);
    setA1FileExtension(e.target.files[0].name.split(".")[1]);
  };

  const a2FileHandler = (e) => {
    setA2File(e.target.files[0]);
    setA2FileExtension(e.target.files[0].name.split(".")[1]);
  };

  const a3FileHandler = (e) => {
    setA3File(e.target.files[0]);
    setA3FileExtension(e.target.files[0].name.split(".")[1]);
  };

  const a4FileHandler = (e) => {
    setA4File(e.target.files[0]);
    setA4FileExtension(e.target.files[0].name.split(".")[1]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setAnswers(props.question.answers);
    setAnswerMode(props.question.answer_mode_id);
  }, []);

  const option = {
    rules: {
      "a1title-value": {
        required: true,
      },
      "a2title-value": {
        required: true,
      },
      "a3title-value": {
        required: true,
      },
      "a4title-value": {
        required: true,
      },
    },
  };

  let xx = new FormValidator("#myForm", option);

  const formHandler = async (e) => {
    e.preventDefault();

    const form = document.forms.myForm;
    const formData = new FormData(form);
    const a1title = formData.get("a1title-value");
    const a1desc = formData.get("a1desc-value");
    const a2title = formData.get("a2title-value");
    const a2desc = formData.get("a2desc-value");
    const a3title = formData.get("a3title-value");
    const a3desc = formData.get("a3desc-value");
    const a4title = formData.get("a4title-value");
    const a4desc = formData.get("a4desc-value");

    if (a1title === "" || a2title === "" || a3title === "" || a4title === "") {
      props.toastPopup(3, "All answer titles are required!");
    } else if (
      a1IsCorrect === "No" &&
      a2IsCorrect === "No" &&
      a3IsCorrect === "No" &&
      a4IsCorrect === "No"
    ) {
      props.toastPopup(3, "Correct answer is required!");
    } else if (correctAnswerCount !== 1) {
      props.toastPopup(3, "Please Select Only One Correct Answer");
    } else {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress);
        },
      };

      let data = new FormData();
      data.append("question_id", props.question.question_id);
      data.append("sub_question_id", props.question.sub_question_id);
      data.append("is_sub", 1);
      data.append("answer1_id", props.question.answers[0].answer_id);
      data.append("answer1_title", a1title);
      data.append("answer1_description", a1desc);
      data.append("file1_extension", a1FIleExtension);
      data.append("answer1_file", a1FIle);
      data.append("is_correct1", a1IsCorrect === "Yes" ? 1 : 0);
      data.append("answer2_id", props.question.answers[1].answer_id);
      data.append("answer2_title", a2title);
      data.append("answer2_description", a2desc);
      data.append("file2_extension", a2FIleExtension);
      data.append("answer2_file", a2FIle);
      data.append("is_correct2", a2IsCorrect === "Yes" ? 1 : 0);
      data.append("answer3_id", props.question.answers[2].answer_id);
      data.append("answer3_title", a3title);
      data.append("answer3_description", a3desc);
      data.append("file3_extension", a3FIleExtension);
      data.append("answer3_file", a3FIle);
      data.append("is_correct3", a3IsCorrect === "Yes" ? 1 : 0);
      data.append("answer4_id", props.question.answers[3].answer_id);
      data.append("answer4_title", a4title);
      data.append("answer4_description", a4desc);
      data.append("file4_extension", a4FIleExtension);
      data.append("answer4_file", a4FIle);
      data.append("is_correct4", a4IsCorrect === "Yes" ? 1 : 0);
      data.append("updated_by", sessionStorage.getItem("id"));

      await axios
        .post(url + "api/answers/edit", data, config)
        .then((response) => {
          if (response?.status === 200) {
            props.toastPopup(1, response.data.message);
            props.closeModal(3);
            props.loadData();
          } else {
            props.toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          props.toastPopup(2, error.message);
        });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          Sub Question Answer Edit
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(3);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div>
        <form className="w-full" id="myForm" onSubmit={formHandler}>
          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Answer 1
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Title
                </label>
                <RichTextEditorComponent
                  id="a1title"
                  htmlAttributes={{ name: "a1title-value" }}
                  value={answers[0]?.answer_title}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description
              </label>
              <RichTextEditorComponent
                id="a1desc"
                htmlAttributes={{ name: "a1desc-value" }}
                value={answers[0]?.answer_description}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className={answerMode === 1 ? "hidden" : "w-full px-3"}>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={a1FileHandler}
                htmlAttributes={"required=true"}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Correct Answer
              </label>
              <div className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <input
                  type="radio"
                  id="1yes"
                  name="correct-answer1"
                  value="Yes"
                  defaultChecked={a1IsCorrect === "Yes"}
                  onChange={(e) => {
                    setA1IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount + 1);
                  }}
                />
                <label for="html" className="px-2">
                  Yes
                </label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  id="1no"
                  defaultChecked={a1IsCorrect === "No"}
                  name="correct-answer1"
                  value="No"
                  onChange={(e) => {
                    setA1IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount - 1);
                  }}
                />
                <label for="css" className="px-2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Answer 2
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Title
                </label>
                <RichTextEditorComponent
                  id="a2title"
                  htmlAttributes={{ name: "a2title-value" }}
                  value={answers[1]?.answer_title}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description
              </label>
              <RichTextEditorComponent
                id="a2desc"
                htmlAttributes={{ name: "a2desc-value" }}
                value={answers[1]?.answer_description}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className={answerMode === 1 ? "hidden" : "w-full px-3"}>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={a2FileHandler}
                htmlAttributes={"required=true"}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Correct Answer
              </label>
              <div className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <input
                  type="radio"
                  id="2yes"
                  name="correct-answer2"
                  value="Yes"
                  defaultChecked={a2IsCorrect === "Yes"}
                  onChange={(e) => {
                    setA2IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount + 1);
                  }}
                />
                <label for="html" className="px-2">
                  Yes
                </label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  id="2no"
                  name="correct-answer2"
                  value="No"
                  defaultChecked={a2IsCorrect === "No"}
                  onChange={(e) => {
                    setA2IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount - 1);
                  }}
                />
                <label for="css" className="px-2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Answer 3
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Title
                </label>
                <RichTextEditorComponent
                  id="a3title"
                  htmlAttributes={{ name: "a3title-value" }}
                  value={answers[2]?.answer_title}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description
              </label>
              <RichTextEditorComponent
                id="a3desc"
                htmlAttributes={{ name: "a3desc-value" }}
                value={answers[2]?.answer_description}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className={answerMode === 1 ? "hidden" : "w-full px-3"}>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={a3FileHandler}
                htmlAttributes={"required=true"}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Correct Answer
              </label>
              <div className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <input
                  type="radio"
                  id="3yes"
                  name="correct-answer3"
                  value="Yes"
                  defaultChecked={a3IsCorrect === "Yes"}
                  onChange={(e) => {
                    setA3IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount + 1);
                  }}
                />
                <label for="html" className="px-2">
                  Yes
                </label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  id="3no"
                  name="correct-answer3"
                  value="No"
                  defaultChecked={a3IsCorrect === "No"}
                  onChange={(e) => {
                    setA3IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount - 1);
                  }}
                />
                <label for="css" className="px-2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Answer 4
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Title
                </label>
                <RichTextEditorComponent
                  id="a4title"
                  htmlAttributes={{ name: "a4title-value" }}
                  value={answers[3]?.answer_title}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description
              </label>
              <RichTextEditorComponent
                id="a4desc"
                htmlAttributes={{ name: "a4desc-value" }}
                value={answers[3]?.answer_description}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className={answerMode === 1 ? "hidden" : "w-full px-3"}>
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={a4FileHandler}
                htmlAttributes={"required=true"}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Correct Answer
              </label>
              <div className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <input
                  type="radio"
                  id="4yes"
                  name="correct-answer4"
                  value="Yes"
                  defaultChecked={a4IsCorrect === "Yes"}
                  onChange={(e) => {
                    setA4IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount + 1);
                  }}
                />
                <label for="html" className="px-2">
                  Yes
                </label>
                &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  id="4no"
                  name="correct-answer4"
                  value="No"
                  defaultChecked={a4IsCorrect === "No"}
                  onChange={(e) => {
                    setA4IsCorrect(e.target.value);
                    setCorrectAnswerCount(correctAnswerCount - 1);
                  }}
                />
                <label for="css" className="px-2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="sticky bottom-0 flex justify-between items-center mt-5 bg-white z-10 bg-hidden">
            <button
              className="bg-[#6e7585] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal(3);
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Update
            </button>
          </div>
        </form>
        <br />
        <LinearProgress
          variant="determinate"
          value={progress}
          className="sticky bottom-0"
        />
      </div>
    </div>
  );
};

export default SubQuestionAnswerEdit;
