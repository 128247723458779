import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { FormValidator } from "@syncfusion/ej2-react-inputs";
import { toast } from "react-toastify";
import axios from "axios";
import { MdOutlineCancel } from "react-icons/md";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import LinearProgress from "@mui/material/LinearProgress";

const NewNews = (props) => {
  const { url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));
  const [formError, setFormError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [newsIsDisable, setNewsIsDisable] = useState(false);

  const [title, setTitle] = useState();
  const [date, setDate] = useState();
  const [file, setFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);

  const option = {
    rules: {
      "defaultRTE-value": {
        required: true,
      },
    },
  };

  let xx = new FormValidator("#myForm", option);

  const addNewsHandle = async (e) => {
    const form = document.forms.myForm;
    const formData = new FormData(form);
    const rteValue = formData.get("defaultRTE-value");
    e.preventDefault();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    };

    if (rteValue !== "" && file !== null) {
      setFormError(false);
      setNewsIsDisable(true);

      let data = new FormData();
      data.append("title", title);
      data.append("description", rteValue);
      data.append("news_date", date);
      data.append("news_image", file);
      data.append("file_extension", fileExtension);

      await axios
        .post(url + "api/news/add", data, config)
        .then((response) => {
          if (response?.status === 200) {
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.warning(error.message);
        })
        .finally(() => {
          formData.set("defaultRTE-value", "");
          props.closeModal();
          clearModel();
        });
    } else {
      setFormError(true);
    }
  };

  const clearModel = () => {
    setProgress(0);
    setTitle(null);
    setDate("");
    setDate(null);
    setNewsIsDisable(false);
    setFile(null);
    setFileExtension(null);
  };

  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setFileExtension(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">New News</p>
        <button
          type="button"
          onClick={props.closeModal}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div>
        <form className="w-full" onSubmit={addNewsHandle} id="myForm">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Title{" "}
                <label className="text-red-700 text-xs font-bold">*</label>
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Question Title"
                required
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description{" "}
                <label className="text-red-700 text-xs font-bold">*</label>
              </label>
              <RichTextEditorComponent
                id="desc"
                htmlAttributes={{ name: "defaultRTE-value" }}
              >
                <Inject
                  services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className=" w-full px-3">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Date{" "}
                  <label className="text-red-700 text-xs font-bold">*</label>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="date"
                  required
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Image{" "}
                <label className="text-red-700 text-xs font-bold">*</label>
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                onChange={fileHandler}
                accept="image/*"
                required={true}
              />
            </div>
          </div>

          <div
            className={
              formError
                ? "flex flex-row -mx-3 mb-6 w-full px-3 text-red-600 text-xs font-semibold"
                : `flex flex-row -mx-3 mb-6 w-full px-3 text-xs font-semibold`
            }
          >
            All fields are required!
          </div>

          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#6e7585] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={props.closeModal}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              disabled={newsIsDisable}
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Add
            </button>
          </div>
        </form>
        <br />
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </div>
  );
};

export default NewNews;
