import React, { useEffect, useState } from "react";
import { Header, Pagination } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { toast } from "react-toastify";
import axios from "axios";
import Modal from "react-modal";
import NewNews from "../components/NewNews";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "70%",
    width: "auto",
    maxWidth: "80%",
  },
};

const News = () => {
  const { url } = useStateContext();
  const [news, setNews] = useState([]);
  const token = useState(sessionStorage.getItem("token"));
  const [modalIsOpen, setIsOpen] = useState(false);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = news.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(news.length / recordsPerPage);

  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      key: "value",
    };

    await axios
      .post(url + "api/news/all", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          let newArray = response.data.output;
          setNews(newArray.reverse());
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  const deleteNews = async (id) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      news_id: id,
      status: 0,
    };

    await axios
      .post(url + "api/news/delete", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          toast.success(response.data.message);
          loadNews(token);
        }
      })
      .catch((error) => {
        toast.warning(error.message);
      });
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    loadNews(token);
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="News" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={openModal}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New News
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add News Modal"
      >
        <NewNews closeModal={closeModal} />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">
                  ID
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-1 w-3 h-3"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 320 512"
                  >
                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                  </svg>
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">
                  Title
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-1 w-3 h-3"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 320 512"
                  >
                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                  </svg>
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                <div class="flex items-center cursor-pointer">
                  Description
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-1 w-3 h-3"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 320 512"
                  >
                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                  </svg>
                </div>
              </th>
              <th scope="col" className="py-3 px-6">
                Date
              </th>
              <th scope="col" className="py-3 px-6">
                Image
              </th>
              <th scope="col" className="py-3 px-6">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                news.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={6} className="py-4 px-6 text-center justify-center">
                No News
              </td>
            </tr>
            {currentRecords.map((row, x) => {
              let num = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6">{indexOfFirstRecord + num}</td>
                  <td className="py-4 px-6">{row.title}</td>
                  <td
                    className="py-4 px-6"
                    dangerouslySetInnerHTML={{ __html: row.description }}
                  ></td>
                  <td className="py-4 px-6">{row.news_date}</td>
                  <td className="py-4 px-6">
                    <img
                      src={row.file_path}
                      alt="news_image"
                      className="w-36"
                    />
                  </td>
                  <td className="py-4 px-6">
                    <button
                      type="button"
                      onClick={(e) => {
                        deleteNews(row.news_id);
                      }}
                      className="p-1 hover:drop-shadow-xl bg-red-500 text-white rounded-lg "
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default News;
