import React, { useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { useState } from "react";
import axios from "axios";

const Notification = () => {
  const { url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    loadNotifications();
  },[]);

  const loadNotifications = () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const data = {
      user_id: sessionStorage.getItem('id')
    }

    axios
      .post(url + "api/notification/all", data, config)
      .then((response) => {
        setNotifications(response.data.output);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="nav-item absolute right-5 md:right-40 top-16 bg-white border-2 border-black p-8 rounded-lg w-96 max-h-96 overflow-scroll">
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200">
            Notifications
          </p>
        </div>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="mt-5 h-1/2">
        {notifications?.map((item, index) => (
          <div
            className="flex items-center leading-8 gap-5 border-b-1 border-color p-3"
          >
            <div>
              <p className="font-semibold dark:text-gray-200">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notification;
