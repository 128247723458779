import { useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import { MdOutlineCancel } from "react-icons/md";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

const PremiumQuestionView = (props) => {
  const questionData = props.question;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">
          Premium Question View
        </p>
        <button
          type="button"
          onClick={() => {
            props.closeModal(2);
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div>
        <div className=" mb-10">
          <p
            className="text-lg text-gray-400"
            dangerouslySetInnerHTML={{
              __html: questionData?.question_description,
            }}
          ></p>
          <p
            className="text-3xl font-extrabold tracking-tight text-slate-900"
            dangerouslySetInnerHTML={{
              __html: questionData?.question_title,
            }}
          ></p>
          <div className="w-full flex flex-row mt-2">
            <div className="text-center">
              <label
                className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                for="grid-last-name"
              >
                {questionData?.question_type}
              </label>
            </div>
            &nbsp;
            <div className="text-center">
              <label
                className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                for="grid-last-name"
              >
                {questionData?.question_category}
              </label>
            </div>
            &nbsp;
            <div className="text-center">
              <label
                className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                for="grid-last-name"
              >
                Points: {questionData?.point}
              </label>
            </div>
          </div>
          <div className="flex">
            {questionData.question_mode_id === 3 ? (
              <ReactAudioPlayer
                src={questionData?.question_file_path}
                autoPlay
                controls
              />
            ) : questionData.question_mode_id === 4 ? (
              <Player playsInline src={questionData?.question_file_path} />
            ) : (
              <div>
                {questionData?.question_file_path !== null ? (
                  <img
                    src={questionData?.question_file_path}
                    alt="img"
                    loading="lazy"
                  />
                ) : (
                  <div></div>
                )}
              </div>
            )}
          </div>
          <div className="flex">
            {questionData?.question_image_path !== null ? (
              <img
                src={questionData?.question_image_path}
                alt="img"
                loading="lazy"
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col mx-4 mb-6">
          <div className="w-full">
            {questionData?.sub_question.length === 0 ? (
              //Answers ui
              <div className="flex flex-col">
                <div className="bg-green-500 rounded-lg">
                  <lable
                    className="block uppercase tracking-wide text-black text-xs font-bold px-2 py-2 text-left"
                    for="grid-last-name"
                  >
                    {questionData?.question_category_id === 4
                      ? "No Sub Questions"
                      : questionData?.answers.length !== 0
                      ? "Answers"
                      : "No Answers"}
                  </lable>
                </div>
                {questionData?.answers.length !== 0 ? (
                  <div
                    className="flex flex-col uppercase tracking-wide text-black text-xs font-bold mt-2 text-left"
                    for="grid-last-name"
                  >
                    {/*answer 1 view*/}
                    <div
                      className={
                        questionData?.answers[0].is_correct === 1
                          ? "flex flex-row bg-green-200  my-2 p-3 shadow-lg"
                          : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                      }
                    >
                      <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                        Answer 1
                      </div>
                      <div className="flex flex-col w-[60%] px-3">
                        <p
                          className="text-base text-black"
                          dangerouslySetInnerHTML={{
                            __html: questionData?.answers[0].answer_title,
                          }}
                        ></p>
                        <div>
                          <p
                            className="text-sm text-black"
                            dangerouslySetInnerHTML={{
                              __html:
                                questionData?.answers[0].answer_description,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="flex w-[30%]">
                        {questionData?.answers[0].answer_file_path !== null ? (
                          <img
                            src={questionData?.answers[0].answer_file_path}
                            alt="img"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                    {/*answer 2 view*/}
                    <div
                      className={
                        questionData?.answers[1].is_correct === 1
                          ? "flex flex-row bg-green-200 rounded-lg my-2 p-3 shadow-lg"
                          : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                      }
                    >
                      <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                        Answer 2
                      </div>
                      <div className="flex flex-col w-[60%] px-3">
                        <p
                          className="text-base text-black"
                          dangerouslySetInnerHTML={{
                            __html: questionData?.answers[1].answer_title,
                          }}
                        ></p>
                        <div>
                          <p
                            className="text-sm text-black"
                            dangerouslySetInnerHTML={{
                              __html:
                                questionData?.answers[1].answer_description,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="flex w-[30%]">
                        {questionData?.answers[1].answer_file_path !== null ? (
                          <img
                            src={questionData?.answers[1].answer_file_path}
                            alt="img"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                    {/*answer 3 view*/}
                    <div
                      className={
                        questionData?.answers[2].is_correct === 1
                          ? "flex flex-row bg-green-200 rounded-lg my-2 p-3 shadow-lg"
                          : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                      }
                    >
                      <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                        Answer 3
                      </div>
                      <div className="flex flex-col w-[60%] px-3">
                        <p
                          className="text-base text-black"
                          dangerouslySetInnerHTML={{
                            __html: questionData?.answers[2].answer_title,
                          }}
                        ></p>
                        <div>
                          <p
                            className="text-sm text-black"
                            dangerouslySetInnerHTML={{
                              __html:
                                questionData?.answers[2].answer_description,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="flex w-[30%]">
                        {questionData?.answers[2].answer_file_path !== null ? (
                          <img
                            src={questionData?.answers[2].answer_file_path}
                            alt="img"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                    {/*answer 4 view*/}
                    <div
                      className={
                        questionData?.answers[3].is_correct === 1
                          ? "flex flex-row bg-green-200 rounded-lg my-2 p-3 shadow-lg"
                          : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                      }
                    >
                      <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                        Answer 4
                      </div>
                      <div className="flex flex-col w-[60%] px-3">
                        <p
                          className="text-base text-black"
                          dangerouslySetInnerHTML={{
                            __html: questionData?.answers[3].answer_title,
                          }}
                        ></p>
                        <div>
                          <p
                            className="text-sm text-black"
                            dangerouslySetInnerHTML={{
                              __html:
                                questionData?.answers[3].answer_description,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="flex w-[30%]">
                        {questionData?.answers[3].answer_file_path !== null ? (
                          <img
                            src={questionData?.answers[3].answer_file_path}
                            alt="img"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            ) : (
              // sub question answer ui
              <div>
                {questionData?.sub_question.map((sub, x) => {
                  return (
                    <div>
                      <div className="flex flex-rowml-8">
                        <p className="text-base text-gray-400 px-2">
                          No: {x + 1}
                        </p>
                        <div className="flex flex-col">
                          <p
                            className="text-base text-gray-400"
                            dangerouslySetInnerHTML={{
                              __html: sub.question_description,
                            }}
                          ></p>
                          <p
                            className="text-2xl font-extrabold tracking-tight text-slate-900"
                            dangerouslySetInnerHTML={{
                              __html: sub.question_title,
                            }}
                          ></p>
                        </div>
                      </div>

                      <div className="w-full flex flex-row mt-2 ml-12">
                        <div className="text-center">
                          <label
                            className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                            for="grid-last-name"
                          >
                            {sub.question_type}
                          </label>
                        </div>
                        &nbsp;
                        <div className="text-center">
                          <label
                            className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                            for="grid-last-name"
                          >
                            {sub.question_category}
                          </label>
                        </div>
                        &nbsp;
                        <div className="text-center">
                          <label
                            className="block uppercase tracking-wide text-black text-xs font-bold mb-2 px-2 py-2  bg-yellow-500 text-center rounded-lg"
                            for="grid-last-name"
                          >
                            Points: {sub.point}
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col mx-12 mb-6">
                        <div className="w-full">
                          <div className="flex flex-col">
                            <div className="bg-green-500 rounded-lg">
                              <lable
                                className="block uppercase tracking-wide text-black text-xs font-bold px-2 py-2 text-left"
                                for="grid-last-name"
                              >
                                {sub.answers.length === 0
                                  ? "No Answers"
                                  : "Answers"}
                              </lable>
                            </div>
                            <div
                              className="flex flex-col uppercase tracking-wide text-black text-xs font-bold mt-2 text-left"
                              for="grid-last-name"
                            >
                              {sub.answers.length === 0 ? (
                                <div></div>
                              ) : (
                                <div>
                                  <div
                                    className={
                                      sub.answers[0].is_correct === 1
                                        ? "flex flex-row bg-green-200  my-2 p-3 shadow-lg"
                                        : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                                    }
                                  >
                                    <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                                      Answer 1
                                    </div>
                                    <div className="flex flex-col w-[60%] px-3">
                                      <p
                                        className="text-base text-black"
                                        dangerouslySetInnerHTML={{
                                          __html: sub.answers[0].answer_title,
                                        }}
                                      ></p>
                                      <div>
                                        <p
                                          className="text-sm text-black"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              sub.answers[0].answer_description,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                    <div className="flex w-[30%]">
                                      {sub.answers[0].answer_file_path !==
                                      null ? (
                                        <img
                                          src={sub.answers[0].answer_file_path}
                                          alt="img"
                                        />
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                  {/*sub question answer 2 view*/}
                                  <div
                                    className={
                                      sub.answers[1].is_correct === 1
                                        ? "flex flex-row bg-green-200  my-2 p-3 shadow-lg"
                                        : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                                    }
                                  >
                                    <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                                      Answer 2
                                    </div>
                                    <div className="flex flex-col w-[60%] px-3">
                                      <p
                                        className="text-base text-black"
                                        dangerouslySetInnerHTML={{
                                          __html: sub.answers[1].answer_title,
                                        }}
                                      ></p>
                                      <div>
                                        <p
                                          className="text-sm text-black"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              sub.answers[1].answer_description,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                    <div className="flex w-[30%]">
                                      {sub.answers[1].answer_file_path !==
                                      null ? (
                                        <img
                                          src={sub.answers[1].answer_file_path}
                                          alt="img"
                                        />
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                  {/*sub question answer 3 view*/}
                                  <div
                                    className={
                                      sub.answers[2].is_correct === 1
                                        ? "flex flex-row bg-green-200  my-2 p-3 shadow-lg"
                                        : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                                    }
                                  >
                                    <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                                      Answer 3
                                    </div>
                                    <div className="flex flex-col w-[60%] px-3">
                                      <p
                                        className="text-base text-black"
                                        dangerouslySetInnerHTML={{
                                          __html: sub.answers[2].answer_title,
                                        }}
                                      ></p>
                                      <div>
                                        <p
                                          className="text-sm text-black"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              sub.answers[2].answer_description,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                    <div className="flex w-[30%]">
                                      {sub.answers[2].answer_file_path !==
                                      null ? (
                                        <img
                                          src={sub.answers[2].answer_file_path}
                                          alt="img"
                                        />
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                  {/*sub question answer 4 view*/}
                                  <div
                                    className={
                                      sub.answers[3].is_correct === 1
                                        ? "flex flex-row bg-green-200  my-2 p-3 shadow-lg"
                                        : "flex flex-row rounded-lg my-2 p-3 shadow-lg bg-slate-100"
                                    }
                                  >
                                    <div className="flex flex-wrap align-middle text-center justify-center w-[10%]">
                                      Answer 4
                                    </div>
                                    <div className="flex flex-col w-[60%] px-3">
                                      <p
                                        className="text-base text-black"
                                        dangerouslySetInnerHTML={{
                                          __html: sub.answers[3].answer_title,
                                        }}
                                      ></p>
                                      <div>
                                        <p
                                          className="text-sm text-black"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              sub.answers[3].answer_description,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                    <div className="flex w-[30%]">
                                      {sub.answers[3].answer_file_path !==
                                      null ? (
                                        <img
                                          src={sub.answers[3].answer_file_path}
                                          alt="img"
                                        />
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PremiumQuestionView;
