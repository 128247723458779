import React, { useEffect, useState } from "react";
import { Header } from "../components";
import { Switch } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";

const Ads = () => {
  const { url } = useStateContext();
  const [currentStatus, setCurrentStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  let token = sessionStorage.getItem("token");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      user_id: parseInt(sessionStorage.getItem("userid")),
    };

    axios
      .post(url + "api/ads/status/get", bodyParameters, config)
      .then((response) => {
        setCurrentStatus(
          response.data.output.ads_available === 0 ? false : true
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "ERR_NETWORK") {
          toast.error("Network Error!");
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const onChange = (e) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      user_id: sessionStorage.getItem("id"),
      ads_available: e ? 1 : 0,
    };

    axios
      .post(url + "api/ads/status/change", bodyParameters, config)
      .then((response) => {
        if (response.data.success) {
          setCurrentStatus(e);
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          toast.error("Network Error!");
        } else {
          if (error.response.status === 401) {
            sessionStorage.clear();
            toast.warning(error.response.data.message);
            window.location.replace("/login");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Manage Ads" />
        </div>
      </div>

      <div className="flex flex-row overflow-x-auto relative p-8">
        <div className="flex flex-row w-full m-4 items-center p-2 justify-between border-[1px] border-black">
          <p className="text-xl font-semibold text-black">
            {currentStatus ? "Disable" : "Enable"} Mobile App Ads
          </p>
          <p className="text-lg font-bold">
            <Switch
              loading={loading}
              checked={currentStatus}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Ads;
