const StatusLabel = (status) => {
  return (
    <div
      className={
        status.status === 1
          ? "bg-blue-600 text-white text-center font-normal py-1 px-1 rounded-lg"
          : "bg-yellow-500  text-white text-center font-normal py-1 px-1 rounded-lg"
      }
    >
      {status.status === 1 ? "Active" : "Deactive"}
    </div>
  );
};
export default StatusLabel;
