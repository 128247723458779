import React from "react";
import 'tw-elements';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, Footer, Sidebar } from "./components";
import {
  Dashboard,
  Questions,
  Users,
  News,
  Login,
  ExamView,
  Exams,
  Scenario,
  Dictionary,
  Version,
  Support,
  LiveExamQuestion,
  MyProfile,
  Subscriptions,
  SystemUsers,
  Ads,
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import QuestionView from "./pages/QuestionView";
import PremiumSubQuestionView from "./pages/PremiumSubQuestionView";
import PremiumQuestionView from "./components/Premium/PremiumQuestionView";
import LiveExam from "./pages/LiveExam";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const { currentMode, activeMenu } = useStateContext();

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <ToastContainer />

      <BrowserRouter>
        <div
          className={
            window.location.pathname === "/login"
              ? "hidden"
              : "flex relative dark:bg-main-dark-bg"
          }
        >
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2"
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              <Routes>
                <Route path="" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/questions" element={<Questions />} />
                <Route path="/scenarios" element={<Scenario />} />
                <Route path="/users" element={<Users />} />
                <Route path="/news" element={<News />} />
                {/* <Route path="/exams" element={<Exams />} />
                <Route path="/exam/:exam_id" element={<ExamView />} /> */}
                <Route path="/dictionary" element={<Dictionary />} />
                <Route
                  path="/exam/:exam_id/:question_id"
                  element={<QuestionView />}
                />
                <Route
                  path="/question/:question_id"
                  element={<PremiumSubQuestionView />}
                />
                <Route
                  path="/questions/:question_id"
                  element={<PremiumQuestionView />}
                />
                <Route path="/version" element={<Version />} />
                <Route path="/support" element={<Support />} />
                <Route path="/liveexam" element={<LiveExam />} />
                <Route
                  path="/liveexam/:exam_id"
                  element={<LiveExamQuestion />}
                />
                <Route path="/myprofile" element={<MyProfile />} />
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route path="/system-users" element={<SystemUsers />} />
                <Route path="/ads" element={<Ads />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
        <div
          className={
            window.location.pathname === "/login"
              ? "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2"
              : "hidden"
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route exact path="/" element={<Login />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
