import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const LiveExamEdit = (props) => {
  const { url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));

  const [examtitle, setExamTitle] = useState(props.exam.exam_title);
  const [description, setDescription] = useState(props.exam.description);
  const [cover_photo, setCoverPhoto] = useState(null);
  const [file_extention, setFileExtension] = useState(
    props.exam.file_extention
  );

  const [rQCount, setRQCount] = useState(props.exam.reading_question_count);
  const [lQCount, setLQCount] = useState(props.exam.listning_question_count);
  const [rQTotTime, setRQTotTime] = useState(
    props.exam.reading_question_total_time
  );
  const [lQTotTime, setLQTotTime] = useState(
    props.exam.listning_question_total_time
  );
  const [rQTime, setRQTime] = useState(props.exam.reading_question_time);
  const [lQTime, setLQTime] = useState(props.exam.listning_question_time);
  const [rSQTime, setRSQTime] = useState(props.exam.reading_sub_question_time);
  const [lSQTime, setLSQTime] = useState(props.exam.listning_sub_question_time);

  const [examDate, setExamDate] = useState(props.exam.exam_start_date_time.split(' ')[0]);
  const [examTime, setExamTime] = useState(props.exam.exam_start_date_time.split(' ')[1]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const editLiveExamCreateHandle = async (e) => {
    e.preventDefault();

    let data = new FormData();
    data.append("exam_id", props.exam.id);
    data.append("exam_title", examtitle);
    data.append("description", description);
    data.append("cover_photo", cover_photo);
    data.append("file_extension", file_extention);
    data.append("reading_question_count", rQCount);
    data.append("listning_question_count", lQCount);
    data.append("reading_question_total_time", rQTotTime);
    data.append("listning_question_total_time", lQTotTime);
    data.append("reading_question_time", rQTime);
    data.append("listning_question_time", lQTime);
    data.append("reading_sub_question_time", rSQTime);
    data.append("listning_sub_question_time", lSQTime);
    data.append("updated_by", sessionStorage.getItem("id"));
    data.append("exam_start_date_time", examDate + " " + examTime);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios
      .post(url + "api/exam/live/edit", data, config)
      .then((response) => {
        if (response?.status === 200) {
          props.toastPopup(1, response.data.message);
          props.fetchData(token);
          props.closeModal();
        } else {
          props.toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        props.toastPopup(2, error.message);
      });
  };
  const fileHandler = (e) => {
    setCoverPhoto(e.target.files[0]);
    setFileExtension(e.target.files[0].name.split(".")[1]);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">Edit Live Exam</p>
        <button
          type="button"
          onClick={() => {
            props.closeModal();
          }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>
      <div>
        <form className="w-full" onSubmit={editLiveExamCreateHandle}>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Reading Question Count
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="rQCount"
                type="number"
                value={rQCount}
                required
                onChange={(e) => {
                  setRQCount(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Listening Question Count
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lQCount"
                type="number"
                value={lQCount}
                required
                onChange={(e) => {
                  setLQCount(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Reading Question Total Time
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="rQTotTime"
                type="number"
                value={rQTotTime}
                required
                onChange={(e) => {
                  setRQTotTime(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Listening Question Total Time
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lQTotTime"
                type="number"
                value={lQTotTime}
                required
                onChange={(e) => {
                  setLQTotTime(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Reading Question Time
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="rQTime"
                type="number"
                value={rQTime}
                required
                onChange={(e) => {
                  setRQTime(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Listening Question Time
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lQTime"
                type="number"
                value={lQTime}
                required
                onChange={(e) => {
                  setLQTime(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Reading Sub Question Time
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="rSQTime"
                type="number"
                value={rSQTime}
                required
                onChange={(e) => {
                  setRSQTime(e.target.value);
                }}
              />
            </div>
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Listening Sub Question Time
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lSQTime"
                type="number"
                value={lSQTime}
                required
                onChange={(e) => {
                  setLSQTime(e.target.value);
                }}
              />
            </div>
          </div>
          {/* Exam Date */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Exam Date
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="date"
                required
                value={examDate}
                onChange={(e) => {
                  setExamDate(e.target.value);
                }}
              />
            </div>
          </div>
          {/* Exam Time */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Exam Time
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="time"
                required
                value={examTime}
                onChange={(e) => {
                  setExamTime(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Exam Title
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Exam Title"
                required
                value={examtitle}
                onChange={(e) => {
                  setExamTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Description
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Description"
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
              >
                Cover Photo
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="file"
                accept="image/*"
                onChange={fileHandler}
              />
            </div>
          </div>
          <div className="flex justify-between items-center mt-5">
            <button
              className="bg-[#33373E] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
              onClick={() => {
                props.closeModal();
              }}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="submit"
              className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LiveExamEdit;
