import { toast } from "react-toastify";
import { Header } from "../components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DefAvatar from "../data/avatar.png";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";

const MyProfile = () => {
  const { url } = useStateContext();
  const token = useState(sessionStorage.getItem("token"));
  const user_id = sessionStorage.getItem("id");

  const [userData, setUserData] = useState([]);
  const [fullName, setFullName] = useState();
  const [eMail, setEmail] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [profilePicture, setProfilePicture] = useState(null);
  const [newPassword, setNewPassword] = useState();
  const [fileExtention, setFileExtension] = useState(null);

  useEffect(() => {
    getMyData();
  }, []);

  const getMyData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const bodyParameters = {
      user_id: user_id,
      user_type_id: 0,
      search_data: null,
    };

    await axios
      .post(url + "api/users", bodyParameters, config)
      .then((response) => {
        setUserData(response.data.output[0]);
        setFullName(response.data.output[0].full_name);
        setEmail(response.data.output[0].email_address);
        setMobileNumber(response.data.output[0].mobile_number);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  //const for logout
  const logout = () => {
    sessionStorage.clear();
    window.location.replace("/login");
  };

  //const for edit profile and change img
  const editProfile = async (e) => {
    e.preventDefault();

    const data = {
      user_id: sessionStorage.getItem("id"),
      user_type_id: 2,
      full_name: fullName,
      email_address: eMail,
      mobile_number: mobileNumber,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(url + "api/user/manage", data, config)
      .then((response) => {
        if (response?.status === 200) {
          toastPopup(1, response.data.message);
          getMyData();
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });

    //change img code
    if (profilePicture !== null) {
      let data1 = new FormData();
      data1.append("user_id", sessionStorage.getItem("id"));
      data1.append("file_extention", fileExtention);
      data1.append("updated_by", 1);
      data1.append("profile_picture", profilePicture);

      const config1 = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await axios
        .post(url + "api/user/image", data1, config1)
        .then((response) => {
          if (response?.status === 200) {
            toastPopup(1, response.data.message);
            getMyData();
          } else {
            toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    }
  };

  const FileHandler = (e) => {
    setProfilePicture(e.target.files[0]);
    setFileExtension(e.target.files[0].name.split(".")[1]);
  };

  //const for password change
  const passwordChange = async () => {
    const data = {
      email_address: eMail,
      password: newPassword,
      reenter_password: confirmPassword,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await axios
      .post(url + "api/password/reset", data, config)
      .then((response) => {
        if (response?.status === 200) {
          toastPopup(1, response.data.message);
          getMyData();
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="My Profile" />
        </div>
      </div>

      <div
        className="flex sm:flex-row flex-col overflow-x-auto relative shadow-md sm:rounded-lg space-x-4"
        style={{ overflow: "hidden" }}
      >
        <div className="basis-1/4 rounded-lg shadow-xl position:fixed">
          <div className="grid justify-items-center ">
            <div className=" flex items-center  h-[100%]">
              <div className="">
                <div className="py-2 justify-center flex px-2">
                  <img
                    src={
                      userData.profile_picture === null
                        ? DefAvatar
                        : userData.profile_picture
                    }
                    alt="profile"
                    className="w-20  rounded-[25%] justify-center"
                  />
                </div>
                <div className="py-1 text-center">
                  <span className="font-semibold text-xl dark:text-gray-200">
                    {userData.full_name}
                  </span>
                </div>
                <div className="py-0 text-center">
                  <span className=" text-gray-500 text-sm dark:text-gray-400">
                    {userData.user_type}
                  </span>
                </div>
                <div className="py-1 text-center">
                  <span className=" text-gray-500 text-sm font-semibold dark:text-gray-400">
                    {userData.email_address}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-end h-[20%] pb-2 w-full">
            <div className="px-2 w-full flex justify-center">
              <button
                type="button"
                className="p-3 w-full hover:drop-shadow-xl bg-[#1E4DB7] hover:bg-[#123588] rounded-lg text-white"
                onClick={logout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="basis-3/4 rounded-lg shadow-xl mt-5 sm:mt-0">
          <Tabs>
            <TabList>
              <Tab>Personal Details</Tab>
              <Tab>Password Change</Tab>
            </TabList>

            <TabPanel>
              {/* Personal Details */}
              <form className="w-full" id="myForm" onSubmit={editProfile}>
                <div className="flex flex-wrap -mx-3 mb-6 px-2">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-last-name"
                    >
                      Full Name
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="full Name"
                      required
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6 px-2">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-last-name"
                    >
                      Email Address
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="email"
                      placeholder="Email Address"
                      required
                      value={eMail}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6 px-2">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-last-name"
                    >
                      Profile Picture
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="file"
                      placeholder="Profile Picture"
                      onChange={FileHandler}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6 px-2">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-last-name"
                    >
                      Mobile Number
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                      required
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center mt-5 px-2 py-2 float-right sm:w-1/4 w-full ">
                  <button
                    type="submit"
                    className="bg-[#1E4DB7] p-3 w-full hover:drop-shadow-xl rounded-lg text-white float-right"
                  >
                    Update
                  </button>
                </div>
              </form>
            </TabPanel>

            <TabPanel>
              {/* Password Change */}
              <div className="flex flex-wrap -mx-3 mb-6 px-2">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Email Address
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="email"
                    placeholder="Email Address"
                    disabled
                    value={eMail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6 px-2">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    New Password
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="password"
                    placeholder="New Password"
                    required
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6 px-2">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Confirm Password
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="password"
                    placeholder="Confirm Password"
                    required
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="mt-5 py-2 px-2 float-right sm:w-2/4 w-full">
                <button
                  type="button"
                  className="p-3 w-full hover:drop-shadow-xl bg-[#1E4DB7] hover:bg-[#123588] rounded-lg text-white"
                  onClick={passwordChange}
                >
                  Update Password
                </button>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
