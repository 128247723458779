import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Header, Pagination } from "../components";
import NewScenario from "../components/NewScenario";
import { useStateContext } from "../contexts/ContextProvider";
import DeleteScenario from "../components/DeleteScenario";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));
const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: ".5em",
    maxHeight: "80%",
    height: "52%",
    width: "auto",
    maxWidth: "80%",
  },
};

const Scenario = () => {
  const { url } = useStateContext();
  const [modalIsOpen, setIsOpen] = useState(false);
  const token = sessionStorage.getItem("token");

  const [scenario, setScenario] = useState([]);

  //consts for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = scenario.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(scenario.length / recordsPerPage);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const bodyParameters = {
      status: 1,
    };

    await axios
      .post(url + "api/exam/scenario/all", bodyParameters, config)
      .then((response) => {
        if (response?.status === 200) {
          setScenario(response.data.output.exam_scenarios);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warning(text);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    getData();
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex flex-row">
        <div className="w-2/4">
          <Header category="Page" title="Scenario" />
        </div>
        <div className="w-2/4 text-right">
          <button
            type="button"
            onClick={openModal}
            className="p-3 hover:drop-shadow-xl bg-[#1E4DB7] text-white rounded-lg "
          >
            New Scenario
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Add News Modal"
      >
        <NewScenario
          getData={getData}
          closeModal={closeModal}
          toastPopup={toastPopup}
        />
      </Modal>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6"></th>
              <th
                scope="col"
                className="py-3 px-6 text-center justify-center border-b-1 border-r-1"
                colSpan={5}
              >
                <div class="flex items-center text-center justify-center">
                  Reading Questions
                </div>
              </th>
              <th
                scope="col"
                className="py-3 px-6 text-center justify-center border-b-1 border-l-1"
                colSpan={6}
              >
                <div class="flex items-center text-center justify-center">
                  Listening Questions
                </div>
              </th>
            </tr>
            <tr>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">ID</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">All</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex  text-center justify-center">Easy</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">Normal</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">Hard</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">Extra Hard</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">All</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">Easy</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">Normal</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">Hard</div>
              </th>
              <th scope="col" className="py-3 px-6 border-1">
                <div class="flex text-center justify-center">Extra Hard</div>
              </th>
              <th scope="col" className="py-3 px-6  border-1">
                <div class="flex text-center justify-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                scenario.length !== 0
                  ? "hidden"
                  : "bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              }
            >
              <td colSpan={12} className="py-4 px-6 text-center justify-center">
                No Scenarios
              </td>
            </tr>
            {currentRecords.map((exam_scenarios, x) => {
              let row = x + 1;
              return (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="py-4 px-6 text-center justify-center">
                    {indexOfFirstRecord + row}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.reading_all_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.reading_easy_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.reading_medium_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.reading_hard_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.reading_extra_hard_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.listning_all_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.listning_easy_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.listning_medium_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.listning_hard_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    {exam_scenarios.listning_extra_hard_question_count}
                  </td>
                  <td className="py-4 px-6 text-center justify-center">
                    <DeleteScenario
                      text-center
                      justify-center
                      status={exam_scenarios.status}
                      exam_scenario_id={exam_scenarios.id}
                      getData={getData}
                      toastPopup={toastPopup}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
export default Scenario;
