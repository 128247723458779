import axios from "axios";
import React from "react";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";

const NewScenario = (props) => {
  const { url } = useStateContext();

  const [readEasyQCount, setReadEasyQCount] = useState(5);
  const [readNormalQCount, setReadNormalQCount] = useState(5);
  const [readHardQCount, setReadHardQCount] = useState(5);
  const [readExtraHardQCount, setReadExtraHardQCount] = useState(5);

  const [listenEasyQCount, setListenEasyQCount] = useState(5);
  const [listenNormalQCount, setListenNormalQCount] = useState(5);
  const [listenHardQCount, setListenHardQCount] = useState(5);
  const [listenExtraHardQCount, setListenExraHardQCount] = useState(5);

  const token = sessionStorage.getItem("token");

  const addExamScenario = async () => {
    let totRead =
      Number(readEasyQCount) +
      Number(readNormalQCount) +
      Number(readHardQCount) +
      Number(readExtraHardQCount);
    let totList =
      Number(listenEasyQCount) +
      Number(listenNormalQCount) +
      Number(listenHardQCount) +
      Number(listenExtraHardQCount);

    if (totRead === 20 && totList === 20) {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        reading_all_question_count: 20,
        reading_easy_question_count: readEasyQCount,
        reading_medium_question_count: readNormalQCount,
        reading_hard_question_count: readHardQCount,
        reading_extra_hard_question_count: readExtraHardQCount,

        listning_all_question_count: 20,
        listning_easy_question_count: listenEasyQCount,
        listning_medium_question_count: listenNormalQCount,
        listning_hard_question_count: listenHardQCount,
        listning_extra_hard_question_count: listenExtraHardQCount,
      };

      await axios
        .post(url + "api/exam/scenario/add", bodyParameters, config)
        .then((response) => {
          if (response?.status === 200) {
            props.toastPopup(1, response.data.message);
            props.getData(token);
            props.closeModal();
          } else {
            props.toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          props.toastPopu(2, error.message);
        });
    } else {
      props.toastPopup(3, "Questions counts doesn't match!");
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="font-bold text-lg dark:text-gray-200">New Scenario</p>
        <button
          type="button"
          onClick={props.closeModal}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray rounded-[50%] text-[#99abb4]"
        >
          <MdOutlineCancel />
        </button>
      </div>

      <div>
        <form className="w-full">
          <div className="flex flex-row -mx-3 mb-6">
            <div className="flex flex-col w-full px-3 mb-6 md:mb-0">
              <div className="text-2xl font-bold">Reading Questions</div>
              <div class="form-body">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="col" className="py-3 px-6 w-1/2">
                        <div class="flex items-center cursor-pointer">
                          Category
                        </div>
                      </th>
                      <th scope="col" className="py-3 px-6 w-1/2">
                        <div class="flex items-center cursor-pointer">
                          Count (
                          {Number(readEasyQCount) +
                            Number(readNormalQCount) +
                            Number(readHardQCount) +
                            Number(readExtraHardQCount)}
                          /20)
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white  hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2 w-1/2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Easy
                        </lable>
                      </td>
                      <td className="py-2 px-2 w-1/2">
                        <input
                          type="number"
                          value={readEasyQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => {
                            setReadEasyQCount(e.target.value);
                          }}
                        ></input>
                      </td>
                    </tr>
                    <tr className="bg-white hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Normal
                        </lable>
                      </td>
                      <td className="py-2 px-2">
                        <input
                          type="number"
                          value={readNormalQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => {
                            setReadNormalQCount(e.target.value);
                          }}
                        ></input>
                      </td>
                    </tr>
                    <tr className="bg-white  hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Hard
                        </lable>
                      </td>
                      <td className="py-2 px-2">
                        <input
                          type="number"
                          value={readHardQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => {
                            setReadHardQCount(e.target.value);
                          }}
                        ></input>
                      </td>
                    </tr>
                    <tr className="bg-white hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Extra Hard
                        </lable>
                      </td>
                      <td className="py-2 px-2">
                        <input
                          type="number"
                          value={readExtraHardQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => {
                            setReadExtraHardQCount(e.target.value);
                          }}
                        ></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex flex-col w-full px-3 mb-6 md:mb-0">
              <div className="text-2xl font-bold">Listening Questions</div>
              <div class="form-body">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className="border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="col" className="py-3 px-6 w-1/2">
                        <div class="flex items-center cursor-pointer">
                          Category
                        </div>
                      </th>
                      <th scope="col" className="py-3 px-6 w-1/2">
                        <div class="flex items-center cursor-pointer">
                          Count (
                          {Number(listenEasyQCount) +
                            Number(listenNormalQCount) +
                            Number(listenHardQCount) +
                            Number(listenExtraHardQCount)}
                          /20)
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white  hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2 w-1/2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Easy
                        </lable>
                      </td>
                      <td className="py-2 px-2 w-1/2">
                        <input
                          type="number"
                          value={listenEasyQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => {
                            setListenEasyQCount(e.target.value);
                          }}
                        ></input>
                      </td>
                    </tr>
                    <tr className="bg-white hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Normal
                        </lable>
                      </td>
                      <td className="py-2 px-2">
                        <input
                          type="number"
                          value={listenNormalQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => {
                            setListenNormalQCount(e.target.value);
                          }}
                        ></input>
                      </td>
                    </tr>
                    <tr className="bg-white  hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Hard
                        </lable>
                      </td>
                      <td className="py-2 px-2">
                        <input
                          type="number"
                          value={listenHardQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) => setListenHardQCount(e.target.value)}
                        ></input>
                      </td>
                    </tr>
                    <tr className="bg-white hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="py-2 px-2">
                        <lable className=" uppercase tracking-wide text-gray-700 text-base font-bold mb-2">
                          Extra Hard
                        </lable>
                      </td>
                      <td className="py-2 px-2">
                        <input
                          type="number"
                          value={listenExtraHardQCount}
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) =>
                            setListenExraHardQCount(e.target.value)
                          }
                        ></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="flex justify-between items-center mt-5">
        <button
          type="button"
          onClick={props.closeModal}
          className="text-white p-3 w-full hover:drop-shadow-xl hover:bg-[#636466] bg-[#999999] rounded-xl"
        >
          Cansel
        </button>
        &nbsp;
        <button
          type="button"
          onClick={addExamScenario}
          className="text-white p-3 w-full hover:drop-shadow-xl hover:bg-[#10275c] bg-[#1E4DB7] rounded-xl"
        >
          Add
        </button>
      </div>
    </div>
  );
};
export default NewScenario;
